import React, { Component, useState, useEffect, useRef } from "react";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Avatar from '@mui/material/Avatar';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RuleIcon from '@mui/icons-material/Rule';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import OutputIcon from '@mui/icons-material/Output';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SettingsIcon from '@mui/icons-material/Settings';
import InputIcon from '@mui/icons-material/Input';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ClearIcon from '@mui/icons-material/Clear';
import SchemaIcon from '@mui/icons-material/Schema';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
/*
import { DataGrid } from '@mui/x-data-grid';
*/
import { MessageHooks } from "../../App";
import { UserInfo, netGet, netPost, netFetch, keycloak } from "../network";
import { InsertBeforeIcon, InsertAfterIcon } from "../icons";
import {
    WorkflowCheckinDialog, WorkflowRevokeCheckoutDialog, WorkflowDeleteDialog,
    StepDeleteDialog, CreateWorkflowDialog, WorkflowSaveDialog, CreateStepWorkflowDialog
} from "./management-workflow-dialogs";
import { EditIndicator } from "./management-workflow-common";
import { CheckBox } from "@mui/icons-material";
import Quill from 'quill';
import {ListContainer , default as QListItem} from 'quill/formats/list';
import { Editor } from 'primereact/editor';
import { EvaluateFormDialog } from "./management-workflow-forms";
import { default as FileUpload, netUpload } from '../file-upload';
import { openPdfDocument, openReadOnlyPdfDocument }  from "../webviewer";

import "./quill-editor.css";
import { render } from "@testing-library/react";

const STEP_EVENTS = [
    {
        name: 'enterStep',
        label: 'Enter Step',
        initiator: false,

    },
    {
        name: 'exitStep',
        label: 'Exit Step',
        initiator: true,
    },
];

const ACTION_EVENTS = [];

const ACTION_RUNTIMES = [
    { value: 'USER', label: 'By User', emailOnly: false},
    { value: 'ENTER', label: 'On Step Enter', emailOnly: false},
    { value: 'CLAIM', label: 'On Step Claim', emailOnly: false},
    { value: 'EXIT', label: 'On Step Exit', emailOnly: false},
    { value: 'ACTION', label: 'On Step Action', emailOnly: true},
];

netGet('/api/workflow/action/list')
    .then(response => response.json())
    .then((data) => {
        // console.log('Actions: ' + JSON.stringify(data));
        if (Array.isArray(data) && data.length > 0 && ACTION_EVENTS.length == 0) {
            data.forEach( item => {
                if ( Array.isArray(item.properties?.events) ) {
                    item.properties.events.forEach( e => ACTION_EVENTS.push(e));
                }
            });
        }
    });

const DEFAULT_VARIABLES = [
    { name: 'workflowName', sample: '737 FCOM Edition', description: 'Name of the workflow runtime instance'},
    { name: 'stepName', sample: 'Review draft', description: 'Name of the current workflow step'},
    { name: 'stepDescription', sample: 'Check, review and annotate draft', description: 'Description of the current workflow step'},
    { name: 'userName', sample: 'foo.bar', description: 'User or account name executing the step or action'},
    { name: 'instanceName', sample: 'Chapter 1', description: 'Name of the workflow runtime instance'},
    { name: 'stepNumber', sample: '3.2', description: 'Step number'},
    { name: 'originator', sample: 'Foo <foo@example.com>', description: 'Workflow originator or submitter'},
    { name: 'peerReviewer', sample: 'Peerable <peer@example.com>', description: 'Peer Reviewer specified for a task or DCR'},
    { name: 'ddrSubmitterEmail', sample: 'Submitter <submitter@example.com>', description: 'DDR Submitter Email address'},
];

const RECIPIENT_VARIABLES = [
    'originator',
    'peerReviewer',
    'ddrSubmitterEmail',
];

const PROCESS_VARIABLES = [
    { name: 'exitStatus', sample: 'FAILED', description: 'Process exit status. One of COMPLETED, FAILED or ABORTED'},
];

const UPLOAD_DLIB_VARIABLES = [
    { name: 'uploadShelfId', sample: '90dcbac9-bd43-46ca-8180-8d92b1e782b4', description: 'Digital Library upload shelf identifier'},
];

const UPLOAD_TARGETS = [
    { name: 'DCR', label: 'DCR Candidate' },
    { name: 'OEM', label: 'OEM Revision'},
    { name: 'DLUP', label: 'Digital Library Upload Shelf'},
    { name: 'REF', label: 'References'}
];

const RENDER_TARGETS = [
    { name: 'DCR', label: 'DCR'},
];

const DOC_UNIT_TARGETS = [
    { name: 'SUPP', label: 'Supplements'},
];

const SPECIAL_ROLES = [
    'PeerReviewer',
];

/*
class MyListContainer extends ListContainer {
	static tagName = ["OL", "UL"];
	static defaultTag = "OL";

	static create(value) {
		return document.createElement(this.getTag(value));
	}

	static getTag(val) { 
                // Our "ql-list" values are "bullet" and "ordered"
		const map = {
			bullet: "UL",
			ordered: "OL",
		};
		return map[val] || this.defaultTag;
	}

	checkMerge() {
		// Only merge if the next list is the same type as this one
		return (
			super.checkMerge() &&
			this.domNode.tagName === this.next.domNode.tagName
		);
	}
}

class MyListItem extends QListItem {
	static requiredContainer = MyListContainer;

	static register() {
		Quill.register(MyListContainer, true);
	}

	optimize(context) {
		if (
			this.statics.requiredContainer &&
			!(this.parent instanceof this.statics.requiredContainer)
		) {
                        // Insert the format value (bullet, ordered) into wrap arguments
			this.wrap(
				this.statics.requiredContainer.blotName,
				MyListItem.formats(this.domNode)
			);
		}
		super.optimize(context);
	}

	format(name, value) {
                // If the list type is different, wrap this list item in a new MyListContainer of that type
		if (
			name === ListItem.blotName &&
			value !== MyListItem.formats(this.domNode)
		) {
			this.wrap(this.statics.requiredContainer.blotName, value);
		}
		super.format(name, value);
	}
}
*/


const getEventLabel = (name) => {
    const ev = STEP_EVENTS.find( e => e.name === name);
    if ( ev ) {
        return ev.label;
    }
    const av = ACTION_EVENTS.find( e => e.name === name);
    if ( av ) {
        return av.label;
    }
    return '';
};

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
};

function InsertVariableDialog(props) {
    const {
        title = 'Insert Workflow Variable',
        open,
        onSave,
        onCancel,
        extraVars = [],
        ...other
    } = props;

    const [variables, setVariables] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);

    useEffect(() => {
        if (open) {
            const vv = [...DEFAULT_VARIABLES, ...UPLOAD_DLIB_VARIABLES];
            if (Array.isArray(extraVars) && extraVars.length > 0) {
                extraVars.forEach(v => vv.push(v));
            }
            vv.sort((a, b) => {
                return typeof a.name === 'string' ? a.name.localeCompare(b.name) : undefined;
            });
            setVariables(vv);
        }

    }, [open, extraVars]);

    /*
    useEffect( () => {
        const vv = [...DEFAULT_VARIABLES];
        if (Array.isArray(extraVars) && extraVars.length > 0) {
            extraVars.forEach(v => vv.push(v));
        }
        vv.sort((a, b) => {
            return a?.name < b?.name;
        });
        setVariables(vv);
    }, [extraVars]);
    */

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            const v = variables[selectedRow];
            onSave(v?.name);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleRowClicked = (event, index) => {
        setSelectedRow(index);
    };
 
    return (
        <Dialog
            maxWidth={'800px'}
            minHeight={'400px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 700,
                    maxHeight: 600,
                    minHeight: 400,
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                    <TableContainer component={Paper} sx={{  }}>
                        <Table stickyHeader sx={{ minWidth: 350 }} aria-label="action table" size="small">
                            <TableHead>
                                <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Sample</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {variables.map((row, index) => (
                                    <TableRow
                                        key={`${row.name}-${index}`}
                                        onClick={(event) => handleRowClicked(event, index)}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                        selected={index === selectedRow}
                                    >
                                        <TableCell component="th" scope="row" sx={{ width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '12em' }}>{row.sample}</TableCell>
                                        <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{row.description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

            </DialogContent>
            <DialogActions sx={{padding: 3, paddingTop: 1}}>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="success" disabled={selectedRow < 0}>Insert</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function InsertFormFieldDialog(props) {
    const {
        title = 'Insert Form Variable',
        open,
        form,
        onSave,
        onCancel,
        extraVars = [],
        ...other
    } = props;

    const [variables, setVariables] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);

    useEffect(() => {
        if (form?.fields) {
            const vf = [ 
                {name: 'originator', label: 'Originator', description: ''},
                {name: 'itContact', label: 'IT Contact', description: ''},
            ]
            const vv = [ ...vf, ...form.fields];
            setVariables(vv);
            // console.log('FORM FIELDS: ' + JSON.stringify(vf));
        }

    }, [open, extraVars, form]);

    /*
    useEffect( () => {
        const vv = [...DEFAULT_VARIABLES];
        if (Array.isArray(extraVars) && extraVars.length > 0) {
            extraVars.forEach(v => vv.push(v));
        }
        vv.sort((a, b) => {
            return a?.name < b?.name;
        });
        setVariables(vv);
    }, [extraVars]);
    */

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            const v = variables[selectedRow];
            onSave(v?.name);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleRowClicked = (event, index) => {
        setSelectedRow(index);
    };
 
    return (
        <Dialog
            maxWidth={'800px'}
            minHeight={'400px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 700,
                    maxWidth: 900,
                    maxHeight: 600,
                    minHeight: 400,
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                    <TableContainer component={Paper} sx={{  }}>
                        <Table stickyHeader sx={{ minWidth: 350 }} aria-label="action table" size="small">
                            <TableHead>
                                <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Label</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {variables.map((row, index) => (
                                    <TableRow
                                        key={`${row.name}-${index}`}
                                        onClick={(event) => handleRowClicked(event, index)}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                        selected={index === selectedRow}
                                    >
                                        <TableCell component="th" scope="row" sx={{ width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '12em' }}>{row.label}</TableCell>
                                        <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{row.description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

            </DialogContent>
            <DialogActions sx={{padding: 3, paddingTop: 1}}>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="success" disabled={selectedRow < 0}>Insert</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}


function EditMessageDialog(props) {
    const {
        title = 'Edit Event Message',
        open = false,
        message,
        onSave,
        onCancel,
        extraVars = [],
        step,
        eventName,
        ...other
    } = props;

    const [text, setText] = useState('');
    const [selectionRange, setSelectionRange] = useState({index: 0, length: 0});
    const [insertVariableOpen, setInsertVariableOpen] = useState(false);
    const [insertFormVariableOpen, setInsertFormVariableOpen] = useState(false);
    const [formVariableEnabled, setFormVariableEnabled] = useState(false);
    const [form, setForm] = useState(null);
    const quillRef = useRef(null);

    useEffect( () => {
        if ( typeof message === 'string' && open) {
            const t = '<p>' + message.replaceAll(/\${(.*?)}/gm,'<strong>$</strong>{<strong>$1</strong>}') + '</p>';
            setQuillValue(t);
            setFormVariableEnabled(false);
            setForm(null);
            if ( eventName === 'exitStep' && step && step.name === 'FORM') {  
                loadForm();
            }
            setInsertFormVariableOpen(false);
        }

    }, [open, step, message]);

    const loadForm = () => {
        netGet('/api/workflow/form/list')
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data)) {
                    // console.log('Forms: ' + data.length);
                    // console.log('STEP: ' + JSON.stringify(step?.properties?.target));
                    if ( step && step.properties?.target ) {
                        const f = data.find( item => item.name === step.properties.target );
                        if ( f ) {
                            // console.log('Found FORM' + JSON.stringify(f));
                            setForm(f);
                            setFormVariableEnabled(true);
                        } else {
                            setForm(null);
                            setFormVariableEnabled(false);
                        }
                    } else {
                        setForm(null);
                        setFormVariableEnabled(false);
                    }
                }
            });
    };

    // it seems that the Quill editor needs to be rendered before we can set its value
    const setQuillValue = (value) => {
        setText(value);
        // console.log("SET QUILL VALUE...");
        /*
        if ( quillRef.current === null ) {
            setTimeout(setQuillValue, 200, value);
        } else {
            setText(value);
        }
        */
    };

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            const div = document.createElement("div");
            div.innerHTML = text;
            const rawText = div.innerText;
            onSave(rawText);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleInsertVariable = () => {
        setInsertVariableOpen(true);
    };

    const handleInsertVariableSave = (vname) => {
        setInsertVariableOpen(false);
        const vtext = '${' + vname + '}';
        const quill = quillRef.current?.getQuill();
        if (quill) {
            let range = quill.getSelection(true);
            if (range.length > 0) {
                quill.deleteText(range.index, range.length);
            }
            quill.insertText(range.index, '$', 'bold', true);
            quill.insertText(range.index + 1, '{', 'bold', false);
            quill.insertText(range.index + 2, vname, 'bold', true);
            quill.insertText(range.index + 2 + vname.length, '}', 'bold', false);
            quill.focus();
            //quillRef.current.focus();
            range = quill.getSelection(true);
            const ed = document.getElementById('ql-editor');
            if ( ed ) {
                ed.focus();
            }
        }
    };

    const handleInsertFormVariable = () => {
        setInsertFormVariableOpen(true);
    };

    const handleInsertFormVariableSave = (vname) => {
        setInsertFormVariableOpen(false);
        handleInsertVariableSave(vname);
    }


    const handleSelectionChange = (event) => {
        // getting selection events from InsertVariableDialog!?
        if (event?.range) {
            setSelectionRange(event.range);
            // console.log('Selection: ' + JSON.stringify(event.range));
        }
    };

    return (
        <Dialog
            maxWidth={'1200px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 800,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Tooltip title="Insert Global Variable">
                        <Button aria-label="insert-global"  variant="outlined" color="primary" onClick={handleInsertVariable} startIcon={ <AttachMoneyIcon/> }>
                            Insert Global Variable
                        </Button>
                    </Tooltip>  
                    <Tooltip title="Insert Local Variable" >
                        <Button aria-label="insert-local"  variant="outlined" color="primary" onClick={handleInsertFormVariable} startIcon={ <AttachMoneyIcon/> }
                            sx={{marginLeft: 1, display: formVariableEnabled ? 'inline-flex' : 'none'}}>
                            Insert Local Variable
                        </Button>
                    </Tooltip>                
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <Editor showHeader={false} value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px', fontSize: '16px' }} 
                        onSelectionChange={handleSelectionChange} ref={quillRef} />
                </Box>
                <InsertVariableDialog open={insertVariableOpen} onCancel={() => setInsertVariableOpen(false)} onSave={handleInsertVariableSave} extraVars={extraVars} />
                <InsertFormFieldDialog open={insertFormVariableOpen} onCancel={() => setInsertFormVariableOpen(false)} onSave={handleInsertFormVariableSave} 
                    extraVars={extraVars} form={form} />
            </DialogContent>
            <DialogActions sx={{padding: 3, paddingTop: 1}}>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="success">Save</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
            
        </Dialog>

    );

}


function WorkflowPublishDialog(props) {
    const {
        title,
        open,
        workflowName,
        onSave,
        onCancel,
        ...other
    } = props;


    const onOKClick = () => {
        if (typeof onSave === 'function') {
            onSave();
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };


    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 900,
                    maxHeight: 600
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography sx={{ display: 'inline' }}>Publish workflow </Typography><Typography sx={{ display: 'inline', fontWeight: 'bold' }}>{workflowName}</Typography><Typography sx={{ display: 'inline' }}>?</Typography>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="success">Publish</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function TestRecipientComponent(props) {
    const {
        value='',
        onChange,
        ...other
    } = props;

    const onFieldChange = (event) => {
        if ( typeof onChange === 'function') {
            onChange(event);
        }
    }

    return (
        <Box sx={{marginTop: 2, marginBottom: 2}} >
            <TextField label="Test Recipient" value={value} onChange={onFieldChange}
                size="small" fullWidth required={true} />
        </Box>
    );

}

function ConfigureEmailDialog(props) {
    const {
        title = 'Configure Send Email Action',
        step,
        stepIndex,
        workflowList,
        workflowIndex,
        actionList,
        open,
        onSave,
        onCancel,
        action,
        edit,
        ...other
    } = props;

    const [bodyText, setBodyText] = useState('');
    const [subject, setSubject] = useState('');
    const [recipient, setRecipient] = useState('');
    
    const [insertVariableOpen, setInsertVariableOpen] = useState(false);
    const [insertFormVariableOpen, setInsertFormVariableOpen] = useState(false);
    const [formVariableEnabled, setFormVariableEnabled] = useState(false);

    const [selectionRange, setSelectionRange] = useState({index: 0, length: 0});
    const [extraVars, setExtraVars] = useState([]);
    const [forms, setForms] = useState([]);
    const [form, setForm] = useState(null);
    const [subjectPos, setSubjectPos] = useState({start: -1, end: -1});
    const [bodyFocused, setBodyFocused] = useState(false);
    const [subjectFocused, setSubjectFocused] = useState(false);
    const [recipientFocused, setRecipientFocused] = useState(false);

    const [testEmailOpen, setTestEmailOpen] = useState(false);
    const [testRecipient, setTestRecipient] = useState('');

    const [runtime, setRuntime] = React.useState('USER');
    const [runtimeAction, setRuntimeAction] = React.useState('');
    const [runtimeStatus, setRuntimeStatus] = useState('');
    const [statusList, setStatusList] = useState([]);

    const quillRef = useRef(null);
    const subjectRef = useRef(null);
    const recipientRef = useRef(null);


    useEffect( () => {
        if ( typeof open === 'boolean' && open) {
            if ( action ) {
                if ( action.properties?.subject ) {
                    setSubject(action.properties.subject);
                } else {
                    setSubject('');
                }
                if ( action.properties?.body ) {
                    console.log("Deferred set Quill body...");
                    setQuillValue(action.properties.body);
                } else {
                    setBodyText('');
                }
                if ( action.properties?.to ) {
                    setRecipient(action.properties.to);
                } else {
                    setRecipient('');
                }
                if ( action.properties?.exec ) {
                    setRuntime(action.properties?.exec);
                } else {
                    setRuntime('USER');
                }
                if ( action.properties?.runtimeAction ) {
                    setRuntimeAction(action.properties.runtimeAction);
                    loadStatusList(action.properties.runtimeAction);
                } else {
                    setRuntimeAction('');
                }
                if ( action.properties?.runtimeStatus ) {
                    setRuntimeStatus(action.properties.runtimeStatus);
                } else {
                    setRuntimeStatus('');
                }
                // console.log('body: ' + action.properties?.body);
            } else {
                setBodyText('');
                setSubject('');
                setRecipient('');
                setRuntime('USER');
            }
            loadForms();
        }

    }, [open, action, step, actionList]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    const renderHeader = () => {
        /*
        return (
            <span class="ql-formats">

                    <select class="ql-font"></select>
                    <select class="ql-size"></select>
                
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>
                
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-indent" value="-1"></button>
                    <button class="ql-indent" value="+1"></button>
                
            </span>
        );
        */
        return (
            <span class="ql-formats">

                    <select class="ql-font"></select>
                    <select class="ql-size"></select>
                
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-strike"></button>
                
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                                
            </span>
        );
    };
    const header = renderHeader();

    // it seems that the Quill editor needs to be rendered before we can set its value
    const setQuillValue = (value) => {
        // console.log('New body value: ' + value);
        setBodyText(value);
        // console.log("SET QUILL VALUE...");
        /*
        if ( quillRef.current === null ) {
            setTimeout(setQuillValue, 1000, value);
        } else {
            setBodyText(value);
            const quill = quillRef.current?.getQuill();
            if (quill) {
                let range = quill.getSelection(true);
                quill.focus();
            }
            console.log("SET QUILL BODY VALUE...");
        }
        */
    };

    const loadForms = () => {
        if (step && step.name === 'FORM') {
            setForm(null);
            setFormVariableEnabled(false);
            netGet('/api/workflow/form/list')
                .then(response => response.json())
                .then(data => {
                    if (data && Array.isArray(data)) {
                        // console.log('Forms: ' + data.length);
                        // console.log('STEP: ' + JSON.stringify(step?.properties?.target));
                        setForms(data);
                        if (step && step.properties?.target) {
                            const f = data.find(item => item.name === step.properties.target);
                            if (f) {
                                // console.log('Found FORM' + JSON.stringify(f));
                                setForm(f);
                                setFormVariableEnabled(true);
                            }
                        }
                    }
                });
        }
        
    };

    const loadStatusList = (actionName) => {
        if ( Array.isArray(actionList) && actionList.length > 0 ) {
            const sl = [];
            sl.push({
                name: 'COMPLETED',
                label: 'COMPLETED',
            });
            const a = actionList.find( b => b.name === actionName);
            if ( a ) {
                if ( Array.isArray(a.properties.status) ) {
                    a.properties.status.forEach( item => sl.push(item));
                }
            }
            setStatusList(sl);
        } else {
            setStatusList([ {
                name: 'COMPLETED',
                label: 'COMPLETED',
            }]);
        }
    };

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            const r = {
                body: bodyText,
                subject: subject,
                to: recipient,
                exec: runtime,
            }
            if ( runtime === 'ACTION') {
                r['runtimeAction'] = runtimeAction;
                r['runtimeStatus'] = runtimeStatus;
            }
            // console.log('template: ' + JSON.stringify(r));
            onSave(r);
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const onTestClick = () => {
        setTestEmailOpen(true);

    };

    const testEmailSave = (fvalue) => {
        setTestEmailOpen(false);
        // console.log('TEST EMAIL SAVE: ' + testRecipient + ' : ' + JSON.stringify(fvalue));
        if (form && Array.isArray(form?.fields)) {
            form.fields.forEach( (field,index) => {
                if ( typeof fvalue[field.name] !== 'undefined') {
                    field.value = fvalue[field.name];
                }
            });
        }
        const tmi = {
            message: bodyText,
            subject: subject,
            recipient: testRecipient,
            form: form,
        };
        netPost('/api/workflow/email/test', tmi)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not send test email: status = ' + resp.status);
                }
            }).catch(error => {
                console.log('Error sending test email: ' + error);
            });
    };

    const handleSelectionChange = (event) => {
        // getting selection events from InsertVariableDialog!?
        if (event?.range) {
            setSelectionRange(event.range);
            // console.log('Selection: ' + JSON.stringify(event.range));
            setBodyFocused(true);
            setSubjectFocused(false);
            setRecipientFocused(false);
        }
    };

    const handleInsertVariable = () => {
        setInsertVariableOpen(true);
    };

    const handleInsertFormVariable = () => {
        setInsertFormVariableOpen(true);
    };

    const handleInsertVariableSave = (vname) => {
        setInsertVariableOpen(false);
        setInsertFormVariableOpen(false);

        if (bodyFocused) {
            const quill = quillRef.current?.getQuill();
            if (quill) {
                let range = quill.getSelection(true);
                if (range.length > 0) {
                    quill.deleteText(range.index, range.length);
                }
                quill.insertText(range.index, '${' + vname + '}');
                // quill.format('code',false);

                /*
                quill.insertText(range.index, '$', 'bold', true);
                quill.insertText(range.index + 1, '{', 'bold', false);
                quill.insertText(range.index + 2, vname, 'bold', true);
                quill.insertText(range.index + 2 + vname.length, '}', 'bold', false);
                */
                
                quill.focus();
                //quillRef.current.focus();
                range = quill.getSelection(true);
                const ed = document.getElementById('ql-editor');
                if (ed) {
                    ed.focus();
                }
            }
        } else if ( subjectFocused && subjectPos.start > -1 ) {
            const vtext = '${' + vname + '}';
            const t = subject;
            if ( t.length == 0 ) {
                setSubject(vtext);
            } else {
                const ss = t.slice(0, subjectPos.start);
                const se = t.slice(subjectPos.end);
                setSubject(ss + vtext + se);
            }
        } else if ( recipientFocused && RECIPIENT_VARIABLES.includes(vname) ) {
            const vtext = '${' + vname + '}';
            setRecipient(vtext);
        }
    };


    const handleSubjectSelectionChange = () => {
        const s = { start: subjectRef.current.selectionStart, end: subjectRef.current.selectionEnd};
        setSubjectPos(s);
        // console.log('Subject pos: ' + JSON.stringify(s));
        setBodyFocused(false);
        setSubjectFocused(true);
        setRecipientFocused(false);
    }

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
    }

    const handleRecipientSelectionChange = () => {
        // console.log('Subject pos: ' + JSON.stringify(s));
        setBodyFocused(false);
        setSubjectFocused(false);
        setRecipientFocused(true);
    }

    const handleRecipientChange = (event) => {
        setRecipient(event.target.value);
    }

    const handleEditorValueChanged = (event) => {
        // console.log("EDITOR changed: " + event.htmlValue);
        setBodyText(event.htmlValue);
    }

    const handleRuntimeChange = (event) => {
        setRuntime(event.target.value);
    };

    const handleRuntimeActionChange = (event) => {
        const aname = event.target.value;
        setRuntimeAction(aname);
        loadStatusList(aname);
    };

    const handleRuntimeStatusChange = (event) => {
        setRuntimeStatus(event.target.value);
    };


    return (
        <Dialog
            maxWidth={'1200px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 980,
                    maxWidth: 1024,
                    maxHeight: 880
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>

                    <Typography sx={{ fontWeight: 'bold', width: '6em' }}>Execution:</Typography>
                    <FormControl sx={{ m: 1, minWidth: 20,  }} size="small">
                        <Select
                            value={runtime}
                            onChange={handleRuntimeChange}
                            sx={{ minWidth: '8em', }}
                            MenuProps={MenuProps}

                        >
                            {ACTION_RUNTIMES.map((rt) => (
                                <MenuItem
                                    key={rt.value}
                                    value={rt.value}
                                >
                                    {rt.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    { runtime === 'ACTION' && 
                        <React.Fragment>
                            <Typography sx={{ fontWeight: 'bold', width: '4em', marginLeft: 4, }}>Action:</Typography>
                            <FormControl sx={{ m: 1, minWidth: 20, }} size="small">
                                <Select
                                    value={runtimeAction}
                                    onChange={handleRuntimeActionChange}
                                    sx={{ minWidth: '8em', }}
                                    MenuProps={MenuProps}

                                >
                                    {Array.isArray(actionList) && actionList.map((a) => (
                                        <MenuItem
                                            key={a.name}
                                            value={a.name}
                                        >
                                            {a.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography sx={{ fontWeight: 'bold', width: '4em', marginLeft: 4, }}>Status:</Typography>
                            <FormControl sx={{ m: 1, minWidth: 20, }} size="small">
                                <Select
                                    value={runtimeStatus}
                                    onChange={handleRuntimeStatusChange}
                                    sx={{ minWidth: '8em', }}
                                    MenuProps={MenuProps}

                                >
                                    {Array.isArray(statusList) && statusList.map((a) => (
                                        <MenuItem
                                            key={a.name}
                                            value={a.name}
                                        >
                                            {a.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </React.Fragment>
                    }
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 3 }}>
                    <Box sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Tooltip title="Insert Global Variable">
                            <Button aria-label="insert-global" variant="outlined" color="primary" onClick={handleInsertVariable} startIcon={<AttachMoneyIcon />}>
                                Insert Global Variable
                            </Button>
                        </Tooltip>
                        <Tooltip title="Insert Local Variable" >
                            <Button aria-label="insert-local" variant="outlined" color="primary" onClick={handleInsertFormVariable} startIcon={<AttachMoneyIcon />}
                                sx={{ marginLeft: 1, display: formVariableEnabled ? 'inline-flex' : 'none' }}>
                                Insert Local Variable
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography sx={{fontWeight: 'bold'}}>Recipient:</Typography>
                    <TextField sx={{width: '100%'}} size="small" 
                        onSelect={handleRecipientSelectionChange}
                        inputRef={recipientRef}
                        value={recipient}
                        onChange={handleRecipientChange}
                        />
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography sx={{fontWeight: 'bold'}}>Subject:</Typography>
                    <TextField sx={{width: '100%'}} size="small" 
                        onSelect={handleSubjectSelectionChange}
                        inputRef={subjectRef}
                        value={subject}
                        onChange={handleSubjectChange}
                        />
                </Box>
                <Box sx={{ paddingTop: '2ex' }}>
                    <Typography sx={{fontWeight: 'bold'}}>Message:</Typography>
                    <Editor showHeader={true} value={bodyText} onTextChange={handleEditorValueChanged} style={{ height: '320px', fontSize: '16px' }} 
                        onSelectionChange={handleSelectionChange} ref={quillRef} headerTemplate={header} />
                </Box>
                <InsertVariableDialog open={insertVariableOpen} onCancel={() => setInsertVariableOpen(false)} onSave={handleInsertVariableSave} extraVars={extraVars} />
                <InsertFormFieldDialog open={insertFormVariableOpen} onCancel={() => setInsertFormVariableOpen(false)} onSave={handleInsertVariableSave} extraVars={extraVars} form={form} />
                <EvaluateFormDialog form={form} open={testEmailOpen} onSave={testEmailSave} onCancel={() => setTestEmailOpen(false)} preview={true} 
                    beforeComponent={<TestRecipientComponent value={testRecipient} onChange={(event) => setTestRecipient(event.target.value)} />} />
            </DialogContent>
            <DialogActions sx={{padding: 3, paddingTop: 1, justifyContent: 'space-between'}}>
                <Button variant="outlined" onClick={onTestClick} sx={{ minWidth: '7em' }} color="info">Test...</Button>
                <scan>
                    <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="success">Save</Button>
                    <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: 2, minWidth: '7em' }}>Cancel</Button>
                </scan>
            </DialogActions>
            
        </Dialog>

    );

}

function UploadActionFileDialog(props) {
    const {
        open,
        onCancel,
        onSave,
        title = 'Select XSL Stylesheet to Upload',
        ...other
    } = props;

    // const [selected, setSelected] = React.useState([]);
    // const [target, setTarget] = React.useState([]);
    // const [selectedTarget, setSelectedTarget] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [progressValue, setProgressValue] = React.useState([]);
    const [progressFactor, setProgressFactor] = React.useState(1);
    const [progressShow, setProgressShow] = React.useState(false);
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState('Upload Error');
    const [errorMessage, setErrorMessage] = React.useState('An error occurred uploading files. Please try again or consult administrator if problem persists.');

    const theme = useTheme();

    React.useEffect(() => {
        setFiles([]);
        setErrorOpen(false);
    }, [open]);

    React.useEffect(() => {
        setProgressValue(fileUploadProgress / progressFactor);
    }, [fileUploadProgress, progressFactor]);

 
    const onErrorClose = () => {
        setErrorOpen(false);
    };


    const onOkClick = () => {
        if (typeof onSave === 'function') {
            onSave(files);
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };
    
    const handleFileChange = (newFile) => {
        setFiles(newFile);
        console.log('File changed: ' + JSON.stringify(newFile) + ' ' + hasNoFiles());
    };

    const hasNoFiles = () => {
        if (!files) {
            return true;
        }
        return !(Array.isArray(files) && files.length > 0);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    [theme.breakpoints.up('lg')]: {
                        width: 800,
                        minWidth: 700,
                        minHeight: 500
                    },
                    [theme.breakpoints.down('lg')]: {
                        width: '100%',
                        minWidth: '100%',
                        minHeight: '90%'
                    },
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ height: '310px', display: 'flex', justifyContent: 'center', alignItems: 'stretch' }}>
                    <FileUpload buttonText='Select' title="Drag 'n' drop XSL files here, or click to select files" accept={{ 'text/xsl': ['.xsl'], }}
                        value={files} onChange={handleFileChange} maxFiles={1} sx={{ border: 'none', width: '100%', height: '100%' }} />
                </Box>
            </DialogContent>
            <DialogActions>
                {/*
                <Box sx={{visibility: progressShow ? 'visible' : 'hidden', minWidth: '500px'}}>
                    <LinearProgress variant="determinate" value={progressValue} />
                </Box>
                */}
                <Box>
                    <Button variant="outlined" onClick={onOkClick} sx={{ minWidth: '7em' }} disabled={!Array.isArray(files) || files.length == 0} >OK</Button>
                    <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: '1em', minWidth: '7em' }}>Cancel</Button>
                </Box>
                {/*
                <FormErrorDialog open={errorOpen} title={errorTitle} message={errorMessage} onClose={onErrorClose} />
                */}
            </DialogActions>
        </Dialog>

    );

}

function UploadActionFile(props) {
    const {
        dest = '/opt/UCS/dlib/uploads',
        open = false,
        label,
        fieldValue = '',
        required,
        upload = false,
        onChange,
        progressShow,
        progressValue,
        ...other
    } = props;

    const [file, setFile] = React.useState(null);
    const [target, setTarget] = React.useState([]);
    const [showClear, setShowClear] = React.useState(false);
    const [nameValue, setNameValue] = React.useState('');
    const [browseOpen, setBrowseOpen] = React.useState(false);
    /*
    const [progressShow, setProgressShow] = React.useState(false);
    const [progressFactor, setProgressFactor] = React.useState(1);
    const [progressValue, setProgressValue] = React.useState(0);
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);
    */
    /*
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState('Upload Error');
    const [errorMessage, setErrorMessage] = React.useState('An error occurred uploading files. Please try again or consult administrator if problem persists.');
    */

    useEffect(() => {
        if (open) {
            console.log('fieldValue: ' + fieldValue);
            if (fieldValue && fieldValue !== '') {
                setNameValue(fieldValue);
                setShowClear(true);
            } else {
                setNameValue('');
                setShowClear(false);
            }
        } else {
            setNameValue('');
            setShowClear(false);
        }

    }, [fieldValue,open]);

    const title = 'Select File to Upload';

    const onNameValueChanged = (value) => {
        setNameValue(value);
        setShowClear(true);
    }

    const onBrowseClick = () => {
        setBrowseOpen(true);
    };

    const onBrowseCancel = () => {
        setBrowseOpen(false);
    };

    const onBrowseSave = (files) => {
        setBrowseOpen(false);
        if (Array.isArray(files) && files.length > 0) {
            const f = files[0];
            setFile(f);
            setNameValue(f.name);
            setShowClear(true);
            // console.log('Files selected: ' + JSON.stringify(f));
            if (typeof onChange == 'function') {
                const ff = [];
                ff.push(f);
                onChange(ff);
            }
        }

    }

    const onClearHandler = () => {
        setFile(null);
        setNameValue('');
        setShowClear(false);
        if (typeof onChange == 'function') {
            const ff = [];
            onChange(ff);
        }
    };

    /*
    const onErrorClose = () => {
        setErrorOpen(false);
    };
    */

    return (
        <Box sx={{
            display: 'grid', gridTemplateColumns: '1fr auto', justifyItems: 'stretch',
            alignItems: 'center', justifyContent: 'stretch', columnGap: '2ex', paddingTop: '2ex'
        }}>
            <TextField label={label} value={nameValue} onChange={(event) => onNameValueChanged(event.target.value)}
                size="small" fullWidth required={required}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end" sx={{ visibility: showClear ? 'visible' : 'hidden' }} >
                            <IconButton type="button" sx={{ p: 0 }} aria-label="clear" onClick={onClearHandler}>
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }} />
            <Button variant="outlined" onClick={onBrowseClick} sx={{ minWidth: '7em' }} >Select...</Button>
            <Box sx={{
                visibility: progressShow ? 'visible' : 'hidden', minWidth: '300px',
                paddingTop: '1ex', paddingBotton: '0.5ex', gridColumnStart: '1', gridColumnEnd: 'span 2'
            }}  >
                <LinearProgress variant="determinate" value={progressValue} />
            </Box>
            <UploadActionFileDialog open={browseOpen} onCancel={onBrowseCancel} onSave={onBrowseSave} title={title} />
            {/* <FormErrorDialog open={errorOpen} title={errorTitle} message={errorMessage} onClose={onErrorClose} /> */}
        </Box>
    );

};

function AddActionDialog(props) {
    const {
        title = 'Add Action',
        step,
        stepIndex,
        workflowList,
        workflowIndex,
        open,
        onSave,
        onCancel,
        actions,
        actionIndex,
        edit,
        stepList,
        startLabel = 'START',
        endLabel = 'END',
        ...other
    } = props;

    const [actionList, setActionList] = React.useState([]);
    const [selectedAction, setSelectedAction] = React.useState('');
    const [uploadTarget, setUploadTarget] = React.useState('DCR');
    const [downTarget, setDownTarget] = React.useState('');
    const [effTarget, setEffTarget] = React.useState('');
    // const [mailTemplate, setMailTemplate] = React.useState({subject: '', body: ''});
    const [configureEmailOpen, setConfigureEmailOpen] = React.useState(false);
    const [editAction, setEditAction] = React.useState(null);
    const [runtime, setRuntime] = React.useState('USER');
    const [fullStepList, setFullStepList] = React.useState([]);
    const [disableStepIndex, setDisableStepIndex] = useState('');
    const [disableRouteList, setDisableRouteList] = useState([]);
    const [disableRouteIndex, setDisableRouteIndex] = useState('');

    // TODO: get this from the action config itself
    const [uploadTargetList, setUploadTargetList] = React.useState(UPLOAD_TARGETS);
    const [effTargetList, setEffTargetList] = React.useState([]);
    const [downProfiles, setDownProfiles] = React.useState([]);

    const [renderTargetList, setRenderTargetList] = useState(RENDER_TARGETS);
    const [renderTarget, setRenderTarget] = useState('DCR');
    const [renderStylesheet, setRenderStylesheet] = useState({});
    const [renderFormat, setRenderFormat] = useState('pdf');
    const [configStylesheets, setConfigStylesheets] = useState([]);
    const [form, setForm] = useState();
    const [evaluateFormOpen, setEvaluateFormOpen] = useState(false);

    const [docUnitTarget, setDocUnitTarget] =React.useState('SUPP');

    const [progressShow, setProgressShow] = React.useState(false);
    const [progressFactor, setProgressFactor] = React.useState(1);
    const [progressValue, setProgressValue] = React.useState(0);
    const [fileUploadNameValue, setFileUploadNameValue] = React.useState('');
    const fileUploadProgress = useSelector((state) => state.fileUpload.progress);

    

    const DIALOG_LEFT_INDENT = '12em';
    const DIALOG_SEP = '1.8em';

    React.useEffect(() => {
        setProgressValue(fileUploadProgress / progressFactor);
    }, [fileUploadProgress, progressFactor]);

    React.useEffect(() => {
        netGet('/api/workflow/action/list')
            .then(response => response.json())
            .then((data) => {
                // console.log('Actions: ' + JSON.stringify(data));
                if (Array.isArray(data) && data.length > 0) {
                    setActionList(data);
                    if ( selectedAction === '') {
                        setSelectedAction(data[0].name);
                    }
                    if (Array.isArray(actions) && actionIndex >= 0) {
                        const a = actions[actionIndex];
                        if (a) {
                            if (a.name) {
                                setSelectedAction(a.name);
                            } else {
                                setSelectedAction(data[0].name);
                            }
                        } else {
                            setSelectedAction(data[0].name);
                        }
                    } else {
                        setSelectedAction(data[0].name);
                    }
                }
            });
        netGet('/api/workflow/config/static/FORM_XSL')
            .then(response => response.json())
            .then( (data) => {
                if ( Array.isArray(data) && data.length > 0 ) {
                    const styles = [];
                    data.forEach( config => {
                        if ( config.properties ) {
                            Object.keys(config.properties).forEach( key => {
                                const v = config.properties[key];
                                if ( v?.path && v?.label ) {
                                    styles.push({path: v.path, label: v.label});
                                }
                            });
                        }
                    });
                    setConfigStylesheets(styles);
                }
            });
    }, []);

    React.useEffect(() => {
        if (Array.isArray(actions) && actionIndex >= 0) {
            const a = actions[actionIndex];
            if (a) {
                const ea = { ...a};
                if (a.name) {
                    setSelectedAction(a.name);
                }
                if ( a.properties?.target) {
                    setEffTarget(a.properties.target);
                    setUploadTarget(a.properties.target);
                    setDownTarget(a.properties.target);
                    setDocUnitTarget(a.properties.target);
                }
                if ( a.properties?.targetStepId ) {
                    const rindex = a.properties?.targetRouteIndex;
                    if ( rindex >= 0 ) {
                        setDisableRouteIndex(rindex);
                    } else {
                        setDisableRouteIndex('');
                    }
                    if ( fullStepList.length > 0 ) {
                        const ti = fullStepList.findIndex ( item => item.sid === a.properties.targetStepId);
                        if ( ti >= 0 ) {
                            setDisableStepIndex(ti);
                            const tstep = fullStepList[ti];
                            if ( Array.isArray(tstep?.routes) ) {
                                setDisableRouteList(tstep.routes);
                            }
                        }
                    } else if ( Array.isArray(workflowList) && workflowIndex >= 0 ) {
                        const fsl = [];
                        const w = workflowList[workflowIndex];
                        if ( w.steps?.length > 0 ) {
                            fsl.push(w.steps[0]);
                        }
                        computeFullStepList(fsl, w.steps);
                        setFullStepList(fsl);
                        const ti = fsl.findIndex ( item => item.sid === a.properties.targetStepId);
                        if ( ti >= 0 ) {
                            setDisableStepIndex(ti);
                            const tstep = fullStepList[ti];
                            if ( Array.isArray(tstep?.routes) ) {
                                setDisableRouteList(tstep.routes);
                            }
                        }
                        // console.log('Full step list: ' + JSON.stringify(fsl));
                    }
                }
                if ( a.properties?.xsl?.label ) {
                    // setFileUploadNameValue(a.properties.xsl.label);
                    // console.log('XSL: ' + JSON.stringify(a.properties.xsl));
                    setRenderStylesheet({label: a.properties.xsl.label, path: a.properties.xsl.path});
                }
                if ( a.properties?.body || a.properties?.subject) {
                    const t = editAction?.properties ? { ...editAction.properties} : {subject: '', body: '', to: ''};
                    if ( a.properties?.body ) {
                        t.body = a.properties.body;
                    }
                    if ( a.properties?.subject ) {
                        t.subject = a.properties.subject;
                    }
                    if ( a.properties?.to) {
                        t.to = a.properties.to;
                    }
                    // setMailTemplate(t);
                    ea.properties = t;
                } else {
                    ea.properties = {body: '', subject: '', to: ''};
                }
                if ( a.properties?.runtime ) {
                    setRuntime(a.properties.runtime);
                } else if ( a.properties?.exec ) {
                    setRuntime(a.properties?.exec);
                } else {
                    setRuntime('USER');
                }
                setEditAction(ea);
            }
        }
        
    }, [actions, actionIndex]);

    React.useEffect( () => {
        let fsl = [];
        if ( Array.isArray(workflowList) && workflowIndex >= 0 ) {
            const w = workflowList[workflowIndex];
            if ( w.steps?.length > 0 ) {
                fsl.push(w.steps[0]);
            }
            computeFullStepList(fsl, w.steps);
            setFullStepList(fsl);
            
            // console.log('Full step list: ' + JSON.stringify(fsl));
        }
        if ( workflowList && workflowIndex >= 0) {
            const w = workflowList[workflowIndex];
            const did = w?.attributes?.document;
            // console.log('AddAction Document id: ' + did);
            if ( did ) {
                netGet('/api/doc/info/' + did)
                    .then(response => response.json())
                    .then((data) => {
                        // console.log('Actions: ' + JSON.stringify(data));
                        if ( Array.isArray(data.metadata?.targets) ) {
                            setEffTargetList(data.metadata.targets);
                            // console.log('AddAction eff targets: ' + JSON.stringify(data.metadata.targets));
                        }
                        if ( data['configBaseKey'] ) {
                            const key = `${data['configBaseKey']}.download.profiles`;
                            const url = `/api/config/vlist?key=${key}`;
                            netGet(url)
                                .then(response => response.json())
                                .then(iprofiles => {
                                    if (Array.isArray(iprofiles)) {
                                        setDownProfiles(iprofiles);
                                    }
                                }).catch(error => {
                                    console.log('Error fetching download profiles: ' + error);
                                });
                        }
                    });
            }

        } else {
            // console.log('AddAction workflowIndex: ' + JSON.stringify(workflowIndex));
            // console.log('AddAction workflowList: ' + JSON.stringify(workflowList));
        }
        /*
        netGet('/api/workflow/action/list')
            .then(response => response.json())
            .then((data) => {
                // console.log('Actions: ' + JSON.stringify(data));
                if (Array.isArray(data) && data.length > 0) {
                    setActionList(data);
                    if (Array.isArray(actions) && actionIndex >= 0) {
                        const a = actions[actionIndex];
                        if (a.name) {
                            setSelectedAction(a.name);
                        } else {
                            setSelectedAction(data[0].name);
                        }
                    } else {
                        setSelectedAction(data[0].name);
                    }
                }
            });
        */
       if ( !open ) {
            setEditAction(undefined);
       }
    },[open,step,stepIndex,workflowList,workflowIndex]);

    const computeFullStepList = (fsl, steps) => {
        if ( Array.isArray(steps) ) {
            steps.forEach( step => {
                if ( step.id > 0 ) {
                    fsl.push(step);
                    if ( step.name === 'CALL' ) {
                        if ( step.properties?.substeps ) {
                            computeFullStepList(fsl, step.properties.substeps);
                        }
                    }
                }
            });

        }
        return fsl;

    };

    const findStep = (sid, steps=fullStepList) => {
        const step = steps.find( item => item.sid === sid);
        return step;

    };

    const getStepNumber = (step) => {
        if ( ! step ) {
            return '';
        }
        let n = '';
        if ( step.pid ) {
            const ps = findStep(step.pid);
            if ( ps ) {
                n = `${ps.id + 1}.${step.id}`;
            } else {
                n = `${step.pid + 1}.${step.id}`;
            }
        } else {
            n = `${step.id + 1}`;
        }
        return n;
    };

    const getStepLabel = (step) => {
        if ( ! step ) {
            return '';
        }
        if (step && step.name === 'END') {
            if ( step.pid ) {
                return endLabel;
            } else {
                return 'END';
            }
        } else if ( step && step.name === 'START' ) {
            if ( step.pid ) {
                return startLabel;
            } else {
                return 'START';
            }
        }
        return step.label;
    };

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            const a = actionList.find(item => item.name === selectedAction);
            const action = { ...a };
            if ( action?.name === 'UPLOAD' ) {
                action.properties = { target: uploadTarget, exec: runtime };
            } else if ( action?.name === 'EDIT_DOC_UNIT' ) {
                action.properties = { target: docUnitTarget, exec: runtime };
            } else if ( action?.name === 'DOWNLOAD') {
                action.properties = { target: downTarget, exec: runtime };
            } else if ( action?.name === 'RUN_APPROVAL_DRAFT' || action?.name === 'PROMOTE_DRAFT' || action?.name === 'OPEN_SHELF' || action?.name === 'COPY_PREV_EDITION') {
                action.properties = { target: effTarget, exec: runtime};
            } else if ( action?.name === 'SEND_EMAIL' ) {
                action.properties = editAction?.properties ? { ...editAction.properties} : { body: '', subject: '', to: '', exec: runtime};
            } else if ( action?.name === 'RENDER_FORM' ) {
                action.properties = { 
                    target: renderTarget, 
                    exec: runtime 
                };
                
                // const files = [];
                if ( renderStylesheet?.label ) {
                    // files.push(renderStylesheet);
                    action.properties.xsl = {
                        label: renderStylesheet.label,
                        path: renderStylesheet.path,
                    };
                }
                // uploadFiles(files, action);
                // return;
            } else if ( action?.name === 'DISABLE_ROUTE') {
                const tstep = fullStepList[disableStepIndex];
                const troute = disableRouteList[disableRouteIndex];
                action.properties = {
                    exec: runtime,
                    targetStepId: tstep ? tstep.sid : -1,
                    targetRouteIndex: disableRouteIndex >= 0 ? disableRouteIndex : -1,
                    targetRouteId: troute ? troute.targetSid : -1,
                }
            } else {
                action.properties = {exec: runtime};
            }
            onSave(action);
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    const handleActionChange = (event) => {
        setSelectedAction(event.target.value);
    };

    const handleUploadTargetChange = (event) => {
        setUploadTarget(event.target.value);
    };

    const handleDocUnitTargetChange = (event) => {
        setDocUnitTarget(event.target.value);
    }

    const handleDownloadTargetChange = (event) => {
        setDownTarget(event.target.value);
    };

    const handleRenderTargetChange = (event) => {
        setRenderTarget(event.target.value);
    };

    const handleEffTargetChange = (event) => {
        setEffTarget(event.target.value);
    };

    const handleRuntimeChange = (event) => {
        setRuntime(event.target.value);
    };

    const onConfigureClick = (event) => {
        setConfigureEmailOpen(true);
    };

    const configureEmailSave = (template) => {
        setConfigureEmailOpen(false);
        const ea = {...editAction};
        ea.properties = template;
        setEditAction(ea);
    };


    const handleRenderStylesheetChange = (event) => {
        const label = event.target.value;
        const ss = configStylesheets.find( s => s.label === label);
        if ( ss ) {
            setRenderStylesheet(ss);
        }
    }

    const onRenderClick = () => {
        if (step && step.name === 'FORM') {
            netGet('/api/workflow/form/list')
                .then(response => response.json())
                .then(data => {
                    if (data && Array.isArray(data)) {
                        // console.log('Forms: ' + data.length);
                        // console.log('STEP: ' + JSON.stringify(step?.properties?.target));
                        if (step && step.properties?.target) {
                            const f = data.find(item => item.name === step.properties.target);
                            if (f) {
                                // console.log('Found FORM' + JSON.stringify(f));
                                setForm(f);
                                setEvaluateFormOpen(true);
                            }
                        }
                    }
                });
        }
    };

    const evaluateFormSave = (formValues) => {
        setEvaluateFormOpen(false);
        if ( selectedAction && formValues) {
            const a = actions[actionIndex];
            const action = { ...a.properties };
            const rform = { ...form};
            if (rform && Array.isArray(rform.fields)) {
                rform.fields.forEach( (field,index) => {
                    field.value = formValues[field.name];
                });
            }
            action.form = rform;
            console.log('RENDER FORM: ' + JSON.stringify(action));
            netPost('/api/workflow/form/render/pdf', action)
                .then(response => response.json() )
                .then(result => {
                    console.log('RENDER FORM RESULT: ' + JSON.stringify(result));
                    if ( result.path && result.label ) {
                        openReadOnlyPdfDocument(result.path, result.label);
                        if (typeof onCancel === 'function') {
                            onCancel('CLOSEALL');
                        }
                    }
                }).catch( error => console.log('Error rendering form: ' + error));
        }
    };

    const getActionDescription = () => {
        if ( selectedAction ) {
            const action = actionList.find( a => a.name === selectedAction);
            console.log('action: ' + JSON.stringify(action));
            if ( action?.description ) {
                return action.description;
            }
        }
        return '';
    };

    const handleDisableStepChange = (event) => {
        const di = event.target.value;
        setDisableStepIndex(di);
        if ( di >= 0 ) {
            const dstep = fullStepList[di];
            // console.log('Found Step: ' + JSON.stringify(dstep));
            if ( Array.isArray(dstep?.routes) && dstep?.routes.length > 0) {
                setDisableRouteList(dstep.routes);
                setDisableRouteIndex('');
            } else {
                setDisableRouteIndex('');
                setDisableRouteList([]);
            }
        }
    };

    const handleDisableRouteChange = (event) => {
        const ri = event.target.value;
        setDisableRouteIndex(ri);
    };

    const getDisableRouteLabel = (route) => {
        const rstep = fullStepList.find(s => s.sid === route?.targetSid);
        if (rstep) {
            return `${getStepNumber(rstep)}: ${getStepLabel(rstep)}`;
        }
        return '';
    };

    return (
        <Dialog
            maxWidth={'900px'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: 720,
                    maxHeight: 630
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>

                <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: DIALOG_LEFT_INDENT }}>Action:</Typography>
                    {Array.isArray(actionList) &&

                        <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }} size="small">
                            <Select
                                value={selectedAction}
                                onChange={handleActionChange}
                                sx={{ minWidth: '11em', width: '100%', maxWidth: '100%' }}
                                MenuProps={MenuProps}

                            >
                                {actionList.map((action) => (
                                    <MenuItem
                                        key={action.name}
                                        value={action.name}
                                    >
                                        {action.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </Box>
                <Box sx={{paddingLeft: '9.8em'}}>
                    <Typography sx={{ fontSize: '80%', color: 'gray' }}>{getActionDescription()}</Typography>
                </Box>
                {selectedAction === 'UPLOAD' &&
                    <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', width: DIALOG_LEFT_INDENT }}>Target:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }} size="small">
                            <Select
                                value={uploadTarget}
                                onChange={handleUploadTargetChange}
                                sx={{ minWidth: '11em', width: '100%', maxWidth: '100%' }}
                                MenuProps={MenuProps}

                            >
                                {uploadTargetList.map((target) => (
                                    <MenuItem
                                        key={target.name}
                                        value={target.name}
                                    >
                                        {target.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {selectedAction === 'EDIT_DOC_UNIT' &&
                    <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', width: DIALOG_LEFT_INDENT }}>Type:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }} size="small">
                            <Select
                                value={docUnitTarget}
                                onChange={handleDocUnitTargetChange}
                                sx={{ minWidth: '11em', width: '100%', maxWidth: '100%' }}
                                MenuProps={MenuProps}

                            >
                                {DOC_UNIT_TARGETS.map((target) => (
                                    <MenuItem
                                        key={target.name}
                                        value={target.name}
                                    >
                                        {target.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {selectedAction === 'DOWNLOAD' &&
                    <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', width: DIALOG_LEFT_INDENT }}>Profile:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }} size="small">
                            <Select
                                value={downTarget}
                                onChange={handleDownloadTargetChange}
                                sx={{ minWidth: '11em', width: '100%', maxWidth: '100%' }}
                                MenuProps={MenuProps}

                            >
                                {downProfiles.map((target) => (
                                    <MenuItem
                                        key={target.name}
                                        value={target.name}
                                    >
                                        {target.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {selectedAction === 'RENDER_FORM' &&
                    <React.Fragment>
                        <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', width: DIALOG_LEFT_INDENT }}>Target:</Typography>
                            <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }} size="small">
                                <Select
                                    value={renderTarget}
                                    onChange={handleRenderTargetChange}
                                    sx={{ minWidth: '11em', width: '100%', maxWidth: '100%' }}
                                    MenuProps={MenuProps}

                                >
                                    {renderTargetList.map((target) => (
                                        <MenuItem
                                            key={target.name}
                                            value={target.name}
                                        >
                                            {target.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </React.Fragment>
                }
                {/* (selectedAction === 'RUN_APPROVAL_DRAFT' || selectedAction === 'PROMOTE_DRAFT' || selectedAction === 'OPEN_SHELF' || selectedAction === 'COPY_PREV_EDITION') &&
                    <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Target:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }} size="small">
                            <Select
                                value={effTarget}
                                onChange={handleEffTargetChange}
                                sx={{ minWidth: '11em', width: '100%', maxWidth: '100%' }}
                                MenuProps={MenuProps}

                            >
                                {effTargetList.filter(item => item.format === 'PDF').map((target) => (
                                    <MenuItem
                                        key={target.value}
                                        value={target.value}
                                    >
                                        {target.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                */}
                {(selectedAction === 'SEND_EMAIL') &&
                    <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center', paddingLeft: 0 }}>

                        <Button variant="outlined" onClick={onConfigureClick} startIcon={<SettingsIcon />} sx={{marginLeft: '8.9em'}}>Configure</Button>

                    </Box>


                }
                {(selectedAction !== 'SEND_EMAIL') &&
                    <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center', paddingLeft: 0 }}>

                        <Typography sx={{ fontWeight: 'bold', width: DIALOG_LEFT_INDENT }}>Execution:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }} size="small">
                            <Select
                                value={runtime}
                                onChange={handleRuntimeChange}
                                sx={{ minWidth: '11em', width: '100%', maxWidth: '100%' }}
                                MenuProps={MenuProps}

                            >
                                {ACTION_RUNTIMES.filter( a => !a.emailOnly ).map((rt) => (
                                    <MenuItem
                                        key={rt.value}
                                        value={rt.value}
                                    >
                                        {rt.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Box>


                }
                {selectedAction === 'RENDER_FORM' &&
                    <React.Fragment>
                        <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center', paddingLeft: 0 }}>
                            <Typography sx={{ fontWeight: 'bold', width: DIALOG_LEFT_INDENT }}>Format:</Typography>
                            <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }}>
                                <RadioGroup
                                    name="add-action-render-format-group"
                                    row
                                    value={renderFormat}
                                    onChange={(event) => setRenderFormat(event.target.value)}
                                >
                                    <FormControlLabel value="html" control={<Radio />} label="HTML" />
                                    <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
                                </RadioGroup>
                            </FormControl>

                        </Box>
                        <Box sx={{ display: 'flex', paddingTop: DIALOG_SEP, alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', width: DIALOG_LEFT_INDENT }}>XSL Stylesheet:</Typography>
                            <FormControl sx={{ m: 1, minWidth: 40, width: '100%' }} size="small">
                                <Select
                                    value={renderStylesheet.label}
                                    onChange={handleRenderStylesheetChange}
                                    sx={{ minWidth: '11em', width: '100%', maxWidth: '100%' }}
                                    MenuProps={MenuProps}

                                >
                                    {configStylesheets.map((style) => (
                                        <MenuItem
                                            key={style.label}
                                            value={style.label}
                                        >
                                            {style.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </React.Fragment>
                }
                {selectedAction === 'DISABLE_ROUTE' &&
                    <React.Fragment>
                        <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Target Step:</Typography>
                            {Array.isArray(stepList) &&

                                <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 9em)' }} size="small">
                                    <Select
                                        value={disableStepIndex}
                                        onChange={handleDisableStepChange}
                                        sx={{ minWidth: '10em', maxWidth: '100%' }}
                                        MenuProps={MenuProps}
                                    >
                                        {fullStepList.map((s, i) => {
                                            return (
                                                <MenuItem
                                                    key={'s' + i}
                                                    value={i}
                                                >
                                                    {`${getStepNumber(s)}: ${getStepLabel(s)}`}
                                                </MenuItem>
                                            )
                                        })

                                        }
                                    </Select>
                                </FormControl>
                            }
                        </Box>
                        <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Target Route:</Typography>
                            {Array.isArray(disableRouteList) &&

                                <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 9em)' }} size="small">
                                    <Select
                                        value={disableRouteIndex}
                                        onChange={handleDisableRouteChange}
                                        sx={{ minWidth: '10em', maxWidth: '100%' }}
                                        MenuProps={MenuProps}
                                    >
                                        {disableRouteList.map((r, i) => {
                                            return (
                                                <MenuItem
                                                    key={'r' + i}
                                                    value={i}
                                                >
                                                    {`${getDisableRouteLabel(r)}`}
                                                </MenuItem>
                                            )
                                        })

                                        }
                                    </Select>
                                </FormControl>
                            }
                        </Box>
                    </React.Fragment>

                }
                <ConfigureEmailDialog workflowList={workflowList} workflowIndex = {workflowIndex} action={editAction} open={configureEmailOpen} 
                    onSave={configureEmailSave} onCancel={() => setConfigureEmailOpen(false)} step={step} actionList={actionList} />
                <EvaluateFormDialog open={evaluateFormOpen} onCancel={() => setEvaluateFormOpen(false)}
                    onSave={evaluateFormSave} preview={true} form={form}/>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: selectedAction === 'RENDER_FORM' ? 'space-between' : 'flex-end', 
                paddingLeft: 2, paddingRight: 2, paddingBlock: 'calc(8px + 1ex)', m: 1 }}>
                {selectedAction === 'RENDER_FORM' && <Button variant="outlined" onClick={onRenderClick} sx={{ minWidth: '7em' }} >Test</Button>}
                <Box sx={{ display: 'inline' }}>
                    <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }} color="success">{edit ? 'Save' : 'Add'}</Button>
                    <Button variant="outlined" onClick={onCancelClick} sx={{ marginLeft: 2, minWidth: '7em' }}>Cancel</Button>
                </Box>
            </DialogActions>
        </Dialog>

    );


};

function AddRouteDialog(props) {
    const {
        title = 'Add Route',
        step,
        stepIndex, // current step
        stepList,
        workflowList,
        workflowIndex,
        open,
        onSave,
        onCancel,
        routes,
        omitStart = false,
        startLabel = 'START',
        endLabel = 'END',
        ...other
    } = props;

    const [routeTypeList, setRouteTypeList] = React.useState(['NEXT', 'STEP']); // ['NEXT', 'STEP', 'WORKFLOW']
    const [routeType, setRouteType] = React.useState('NEXT');
    const [selectedWorkflow, setSelectedWorkflow] = React.useState(-1);
    const [workflowEnabled, setWorkflowEnabled] = React.useState(true);
    const [gotoStepIndex, setGotoStepIndex] = React.useState(-1);
    const [stepEnabled, setStepEnabled] = React.useState(true);
    const [fullStepList, setFullStepList] = React.useState([]);
    const [addReturnValue, setAddReturnValue] = React.useState(false);

    React.useEffect(() => {
        let fsl = [];
        if ( Array.isArray(workflowList) && workflowIndex >= 0 ) {
            const w = workflowList[workflowIndex];
            if ( w.steps?.length > 0 ) {
                fsl.push(w.steps[0]);
            }
            computeFullStepList(fsl, w.steps);
            setFullStepList(fsl);
            // console.log('Full step list: ' + JSON.stringify(fsl));
        }
        let hasNext = Array.isArray(routes) ? routes.find(item => item.name === 'NEXT') : false;
        const si = fsl.findIndex ( s => s.sid === step.sid);
        if (hasNext) {
            // omit next
            setRouteTypeList(['STEP']);
            setSelectedWorkflow(workflowIndex);
            setRouteType('STEP');
            let next = stepIndex + 1;
            if ( si >= 0 ) {
                next = si + 1;
                if ( next >= fsl.length ) {
                    next = si;
                }
            } else {
                if (next >= stepList.length) {
                    next = stepIndex;
                }
            }
            setGotoStepIndex(next);
            setStepEnabled(true);
            setWorkflowEnabled(false);
        } else {
            setRouteTypeList(['NEXT', 'STEP']);
            setSelectedWorkflow(workflowIndex);
            setRouteType('NEXT');
            let next = stepIndex + 1;
            if ( si >= 0 ) {
                next = si + 1;
                if ( next >= fsl.length ) {
                    next = si;
                }
            } else {
                if (next >= stepList.length) {
                    next = stepIndex;
                }
            }
            setGotoStepIndex(next);
            setStepEnabled(false);
            setWorkflowEnabled(false);
        }
        setAddReturnValue(false);
        
    }, [routes, stepList, stepIndex, workflowList, workflowIndex, step]);

    // 
    const computeFullStepList = (fsl, steps) => {
        if ( Array.isArray(steps) ) {
            steps.forEach( step => {
                if ( step.id > 0 ) {
                    fsl.push(step);
                    if ( step.name === 'CALL' ) {
                        if ( step.properties?.substeps ) {
                            computeFullStepList(fsl, step.properties.substeps);
                        }
                    }
                }
            });

        }
        return fsl;

    };

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            let r = {
                name: routeType,
                targetSid: fullStepList[gotoStepIndex].sid,
                targetId: fullStepList[gotoStepIndex].id,
                targetWorkflowId: selectedWorkflow >= 0 ? workflowList[selectedWorkflow].id : -1,
            };
            if ( addReturnValue ) {
                r['tasks'] = ['ADD_RETURN'];
            }
            onSave(r);
        }
    };

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const handleRouteTypeChange = (event) => {
        setRouteType(event.target.value);
        setWorkflowEnabled(event.target.value === 'WORKFLOW');
        if (event.target.value !== 'WORKFLOW') {
            setSelectedWorkflow(workflowIndex);
        }
        setStepEnabled(event.target.value !== 'NEXT');
        if (event.target.value === 'NEXT') {
            setSelectedWorkflow(workflowIndex);
            let next = stepIndex + 1;
            if (next >= stepList.length) {
                next = stepIndex;
            }
            setGotoStepIndex(next);
        }
    };

    const handleWorkflowChange = (event) => {
        setSelectedWorkflow(event.target.value);
    }

    const handleStepChange = (event) => {
        setGotoStepIndex(event.target.value);
    }

    const findStep = (sid, steps=fullStepList) => {
        const step = steps.find( item => item.sid === sid);
        return step;

    };

    const getStepNumber = (step) => {
        if ( ! step ) {
            return '';
        }
        let n = '';
        if ( step.pid ) {
            const ps = findStep(step.pid);
            if ( ps ) {
                n = `${ps.id + 1}.${step.id}`;
            } else {
                n = `${step.pid + 1}.${step.id}`;
            }
        } else {
            n = `${step.id + 1}`;
        }
        return n;
    };

    const getStepLabel = (step) => {
        if ( ! step ) {
            return '';
        }
        if (step && step.name === 'END') {
            if ( step.pid ) {
                return endLabel;
            } else {
                return 'END';
            }
        } else if ( step && step.name === 'START' ) {
            if ( step.pid ) {
                return startLabel;
            } else {
                return 'START';
            }
        }
        return step.label;
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    const handleReturnChange = (event) => {
        setAddReturnValue(event.target.checked);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minWidth: '500px',
                    width: '700px',
                    maxWidth: '700px',
                    maxHeight: '500px',
                    height: 500
                }
            }}>
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Type:</Typography>
                    {Array.isArray(routeTypeList) &&

                        <FormControl sx={{ m: 1, minWidth: 40, width: '11em' }} size="small">
                            <Select
                                value={routeType}
                                onChange={handleRouteTypeChange}
                                sx={{ minWidth: '11em', maxWidth: '100%' }}
                                MenuProps={MenuProps}

                            >
                                {routeTypeList.map((rt) => (
                                    <MenuItem
                                        key={rt}
                                        value={rt}
                                    >
                                        {rt}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Target Workflow:</Typography>
                    {Array.isArray(routeTypeList) &&

                        <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 9em)' }} size="small">
                            <Select
                                value={selectedWorkflow}
                                onChange={handleWorkflowChange}
                                sx={{ minWidth: '10em', maxWidth: '100%' }}
                                MenuProps={MenuProps}
                                readOnly={!workflowEnabled}
                                disabled={!workflowEnabled}
                            >
                                {workflowList.map((w, i) => (
                                    <MenuItem
                                        key={'w' + i}
                                        value={i}
                                    >
                                        {w.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '2em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '10em' }}>Target Step:</Typography>
                    {Array.isArray(stepList) &&

                        <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 9em)' }} size="small">
                            <Select
                                value={gotoStepIndex}
                                onChange={handleStepChange}
                                sx={{ minWidth: '10em', maxWidth: '100%' }}
                                MenuProps={MenuProps}
                                readOnly={!stepEnabled}
                            >
                                {/* stepList.map((w, i) => {

                                    return (omitStart && i > 0) || !omitStart ?
                                        <MenuItem
                                            key={'s' + i}
                                            value={i}
                                        >
                                            {`${omitStart ? i : i + 1}: ${getStepLabel(w)}`}
                                        </MenuItem> : null
                                }) */}
                                { fullStepList.map ( (s, i) => {
                                    return (
                                        <MenuItem
                                            key={'s'+i}
                                            value={i}
                                            >
                                                {`${getStepNumber(s)}: ${getStepLabel(s)}`}
                                            </MenuItem>
                                    )
                                    })

                                }
                            </Select>
                        </FormControl>
                    }
                </Box>
                <Box sx={{ paddingTop: '2em', paddingLeft: '10em'}}>
                    <FormControlLabel control={<Checkbox checked={addReturnValue} onChange={handleReturnChange} />} label="Add Return Route" />
                </Box>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 'calc(8px + 2ex)', paddingBlock: 'calc(8px + 1ex)' }}>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
};


function EditStepDialog(props) {
    const {
        title,
        step,
        stepIndex,
        allSteps,
        allRoles,
        workflowList,
        workflowIndex,
        open,
        onSave,
        onCancel,
        omitStart = false,
        startLabel = 'START',
        endLabel = 'END',
        ...other
    } = props;

    const [typeValue, setTypeValue] = React.useState(null);
    const [nameValue, setNameValue] = React.useState(null);
    const [descriptionValue, setDescriptionValue] = React.useState(null);
    const [rolesValue, setRolesValue] = React.useState([]);
    const [locksMultiple, setLocksMultiple] = React.useState(false);
    const [routeData, setRouteData] = React.useState([]);
    const [actionData, setActionData] = React.useState([]);
    const [eventData, setEventData] = React.useState([]);
    const [selectedActionIndex, setSelectedActionIndex] = React.useState(-1);
    const [addRouteOpen, setAddRouteOpen] = React.useState(false);
    const [selectedRoute, setSelectedRoute] = React.useState(-1);
    const [deleteRouteEnabled, setDeleteRouteEnabled] = React.useState(false);
    const [moveUpRouteEnabled, setMoveUpRouteEnabled] =useState(false);
    const [moveDownRouteEnabled, setMoveDownRouteEnabled] = useState(false);
    const [addActionOpen, setAddActionOpen] = React.useState(false);
    const [editActionOpen, setEditActionOpen] = React.useState(false);
    const [editActionEnabled, setEditActionEnabled] = React.useState(false);
    const [deleteActionEnabled, setDeleteActionEnabled] = React.useState(false);
    const [moveUpActionEnabled, setMoveUpActionEnabled] =useState(false);
    const [moveDownActionEnabled, setMoveDownActionEnabled] = useState(false);
    const [fullStepList, setFullStepList] = React.useState([]);
    const [actionEventData, setActionEventData] = React.useState([]);
    const [editEvent, setEditEvent] = useState(null);
    const [messageEditOpen, setMessageEditOpen] = useState(false);
    const [extraVars, setExtraVars] = useState([]);
    const [configureEmailOpen, setConfigureEmailOpen] = useState(false);
    const [emailActionData, setEmailActionData] = useState(null);
    const [formsList, setFormsList] = useState([]);
    const [formValue, setFormValue] = useState('');
    const [actionList, setActionList] = useState([]);


    // const [eventEnterRolesValue, setEventEnterRolesValue] = React.useState([]);
    // { event: {}, enable: true, message: ''} by event.name, event is event definition from step or action
    const [eventValue, setEventValue] = React.useState({});

    const theme = useTheme();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 320,
            },
        },
    };

    /*
    const routeColumns = [
        { field: 'id', headerName: 'ID', width: 60, sortable: false},
        { field: 'name', headerName: 'Type', width: 100, sortable: false },
        { field: 'dest', headerName: 'Destination', resizable: false, sortable: false}
    ];
    */

    React.useEffect(() => {
        if (step) {
            let fsl = [];
            if ( Array.isArray(workflowList) && workflowIndex >= 0 ) {
                const w = workflowList[workflowIndex];
                if ( w.steps?.length > 0 ) {
                    fsl.push(w.steps[0]);
                }
                computeFullStepList(fsl, w.steps);
                setFullStepList(fsl);
                // console.log('Full step list: ' + JSON.stringify(fsl));
            }
            setNameValue(step.label);
            setDescriptionValue(step.description);
            if (Array.isArray(step.roles)) {
                setRolesValue(step.roles);
            } else {
                setRolesValue([]);
            }
            if (step.properties && step.properties.multiLock) {
                setLocksMultiple(step.properties.multiLock);
            } else {
                setLocksMultiple(false);
            }
            netGet('/api/workflow/step/def/list?name=' + step.name)
                .then(response => response.json())
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        const sdef = data[0];
                        setTypeValue(sdef.label);
                    }
                });
            if (step.routes) {
                //console.log('Routes: ' + JSON.stringify(step.routes));
                setRouteData(step.routes.map((item, index) => {
                    let r = {
                        id: index,
                        name: item.name,
                        targetId: item.targetId,
                        targetSid: item.targetSid,
                        targetWorkflowId: item.targetWorkflowId,
                        tasks: item.tasks,
                    };
                    resolveDestination(r);

                    return r;
                }))
            } else {
                const r = [
                    { id: 0, name: 'NEXT' }
                ];
                // console.log('Setting routes to: ' + JSON.stringify(r));
                setRouteData(r);
            }
            if (step.properties?.actions) {
                setActionData(step.properties.actions);
                loadActionEvents(step.properties.actions);
            } else {
                setActionData([]);
            }
            if ( Array.isArray(step.properties?.events) ) {
                // notifications
                setEventData(step.properties.events);
                loadEventValue(step.properties.events, step.properties.actions);
                /*
                step.properties.events.forEach( event => {
                    if ( event.name === 'enterStep' ) {
                        setEventEnterEnable(typeof event.enable === 'boolean' ? event.enable : false);
                        setEventEnterMessage(typeof event.message === 'string' ? event.message : '');
                        setEventEnterRolesValue(Array.isArray(event.roles) ? event.roles : []);
                    }
                });
                */
            } else {
                setEventData([]);
                //setEventEnterEnable(false);
                // setEventEnterMessage('');
                // setEventEnterRolesValue([]);
                setEventValue({});
            }
            if ( step.name === 'FORM') {
                setFormValue(step.properties?.target);
                loadForms();
            }
        } else {
            setTypeValue('');
            setNameValue('');
            setDescriptionValue('');
            setRolesValue([]);
            setActionData([]);
            setEventData([]);
            //setEventEnterEnable(false);
            //setEventEnterMessage('');
            // setEventEnterRolesValue([]);
            setEventValue({});
            setLocksMultiple(false);
            setFormsList([]);
            setFormValue('');
        }
        if ( !open) {
            setMessageEditOpen(false);
        } else {
            setSelectedActionIndex(-1);
            setMoveUpActionEnabled(false);
            setMoveDownActionEnabled(false);
            setDeleteActionEnabled(false);
            setSelectedRoute(-1);
            setMoveUpRouteEnabled(false);
            setMoveDownRouteEnabled(false);
            setDeleteRouteEnabled(false);
        }
    }, [step, open]);

    React.useEffect(() => {
        netGet('/api/workflow/action/list')
            .then(response => response.json())
            .then((data) => {
                // console.log('Actions: ' + JSON.stringify(data));
                if (Array.isArray(data) && data.length > 0) {
                    setActionList(data);
                }
            });

    }, []);

    const computeFullStepList = (fsl, steps) => {
        if ( Array.isArray(steps) ) {
            steps.forEach( step => {
                if ( step.id > 0 ) {
                    fsl.push(step);
                    if ( step.name === 'CALL' ) {
                        if ( step.properties?.substeps ) {
                            computeFullStepList(fsl, step.properties.substeps);
                        }
                    }
                }
            });

        }
        return fsl;

    };

    const loadForms = () => {
        netGet('/api/workflow/form/list')
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data)) {
                    // console.log('Forms: ' + data.length);
                    setFormsList(data.sort( (a,b) => a.label.localeCompare(b.label)));
                    
                }
            });
    };

    const onOKClick = () => {
        if (typeof onSave === 'function') {
            // console.log('Event Data Save: ' + JSON.stringify(eventData));
            let fv;
            if ( step.name === 'FORM' && formValue && formValue !== '' ) {
                fv = formValue;
                onSave(nameValue, descriptionValue, rolesValue, locksMultiple, routeData, actionData, eventData, fv);
            } else {
                onSave(nameValue, descriptionValue, rolesValue, locksMultiple, routeData, actionData, eventData);
            }
            
        }
    }

    const onCancelClick = () => {
        onClose();
    };

    const onClose = () => {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    };

    const nameChanged = (event) => {
        setNameValue(event.target.value);
    };

    const descriptionChanged = (event) => {
        setDescriptionValue(event.target.value);
    };

    const handleRolesChange = (event) => {
        const {
            target: { value },
        } = event;
        let nval = typeof value === 'string' ? value.split(',') : value;
        setRolesValue(nval);

        /* adjust enterStep 
        const erv = [];
        eventEnterRolesValue.forEach( item => {
            if ( nval.includes(item) ) {
                erv.push(item);
            }
        });
        setEventEnterRolesValue(erv);
        */
    };

    const handleClearClick = (event) => {
        setRolesValue([]);
    };

    const loadEventValue = (events, actions) => {
        const ee = {};
        /*
        step.properties.events.forEach( event => {
            if ( event.name === 'enterStep' ) {
                setEventEnterEnable(typeof event.enable === 'boolean' ? event.enable : false);
                setEventEnterMessage(typeof event.message === 'string' ? event.message : '');
                setEventEnterRolesValue(Array.isArray(event.roles) ? event.roles : []);
            }
        });
        */
       STEP_EVENTS.forEach( sevent => {
            const e = events.find( item => item.name === sevent.name);
            if ( e ) {
                ee[e.name] = { enable: false, initiator: false, message: '', roles: [], ...e};
            } else {
                ee[sevent.name] = { name: sevent.name, enable: false, message: '', roles: [], initiator: sevent.initiator};
            }
       });
       if ( Array.isArray(actions) ) {
            actions.forEach( action => {
                if (Array.isArray(action.properties?.events)) {
                    action.properties.events.forEach(aevent => {
                        const e = events.find(item => item.name === aevent.name);
                        if (e) {
                            ee[e.name] = { enable: false, initiator: false, message: '', roles: [], ...e };
                        } else {
                            ee[aevent.name] = { name: aevent.name, enable: false, message: '', roles: [], initiator: aevent.initiator };
                        }
                    });
                }
            });
       }
       setEventValue(ee);
    };

    const storeEventData = (name,enable,roles,message, initiator) => {
        const ed = [...eventData];
        const e = ed.find( item => item.name === name);
        if ( e ) {
            e.enable = enable;
            e.roles = roles;
            e.message = message;
            e.initiator = initiator;
        } else {
            ed.push({
                name: name,
                enable: enable,
                roles: roles,
                message: message,
                initiator: initiator,
            });
        }
        setEventData(ed);
    };

    const storeEvent = (event) => {
        const newValue = {...eventValue};
        newValue[event.name] = event;
        setEventValue(newValue);
        storeEventData(event.name, event.enable, event.roles, event.message, event.initiator);
    };

    const handleEventRolesChange = (evalue, eventDef) => {
        const {
            target: { value },
        } = evalue;
        let nval = typeof value === 'string' ? value.split(',') : value;
        const ev = eventValue[eventDef.name];
        const ce = { name: eventDef.name, enable: false, roles: nval, message: '', initiator: false};
        if ( ev ) {
            ce.enable = ev.enable;
            ce.message = ev.message;
            ce.initiator = ev.initiator;
        }
        storeEvent(ce);
    };

    const handleEventRolesClearClick = (evalue,eventDef) => {
        // setEventEnterRolesValue([]);
        const ev = eventValue[eventDef.name];
        const ce = { name: eventDef.name, enable: false, roles: [], message: '', initiator: false};
        if ( ev ) {
            ce.enable = ev.enable;
            ce.message = ev.message;
            ce.initiator = ev.initiator;
        }
        storeEvent(ce);
    };

    const handleEventEnableChange = (evalue, eventDef) => {
        console.log('event enable change: '  + eventDef.name + ' = ' + evalue.target.checked);
        // setEventEnterEnable(evalue.target.checked);
        const ev = eventValue[eventDef.name];
        const ce = { name: eventDef.name, enable: evalue.target.checked, roles: [], message: '', initiator: false};
        if ( ev ) {
            ce.roles = ev.roles;
            ce.message = ev.message;
            ce.initiator = ev.initiator;
        }
        storeEvent(ce);
    };

    const handleEventMessageChange = (evalue, eventDef) => {
        // setEventEnterMessage(evalue.target.value);
        const ev = eventValue[eventDef.name];
        const ce = { name: eventDef.name, enable: false, roles: [], message: evalue.target.value, initiator: false};
        if ( ev ) {
            ce.enable = ev.enable;
            ce.roles = ev.roles;
            ce.initiator = ev.initiator;
        }
        storeEvent(ce);
    };


    const getStyles = (role) => {
        return {
            fontWeight:
                rolesValue.includes(role)
                    ? theme.typography.fontWeightBold
                    : theme.typography.fontWeightRegular,
            fontStyle:
                SPECIAL_ROLES.includes(role)
                    ? 'italic' : 'normal',
        };
    };

    const getEventStyles = (eventName, role) => {
        const ev = eventValue[eventName];
        if ( ev ) {
            return {
                fontWeight:
                    ev.roles.includes(role) 
                        ? theme.typography.fontWeightBold
                        : theme.typography.fontWeightRegular,
                fontStyle: rolesValue.includes(role) || 'initiator' === role
                        ? 'normal'
                        : 'italic',
            };
        }

        return { fontWeight: theme.typography.fontWeightRegular, fontStyle: 'normal'};

    };


    const makeEventRow = (event,index) => {
        return (
            <TableRow
                key={`nt-event-${event.name}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row" sx={{ width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                    {event.label}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ width: '4em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                    <Checkbox checked={eventValue[event.name]?.enable} onChange={(value) => handleEventEnableChange(value, event)} />
                </TableCell>
                <TableCell sx={{ padding: 0, lineHeight: '0.9rem', width: '30em' }}>
                    <FormControl sx={{ m: 1, minWidth: 120, width: '27em' }} size="small">
                        <Select
                            multiple
                            size="small"
                            value={Array.isArray(eventValue[event.name]?.roles) ? eventValue[event.name]?.roles : []}
                            onChange={(value) => handleEventRolesChange(value, event)}
                            sx={{ minWidth: '20em', maxWidth: '100%' }}
                            MenuProps={MenuProps}
                            disabled={!eventValue[event.name]?.enable}
                            endAdornment={
                                <React.Fragment>
                                    <IconButton
                                        size="small"
                                        sx={{ display: eventValue[event.name]?.roles.length > 0 ? "" : "none" }}
                                        onClick={(value) => handleEventRolesClearClick(value, event)}>
                                        <ClearIcon fontSize="inherit" />
                                    </IconButton>
                                    <Box sx={{ width: '1em' }}>{' '}</Box>
                                </React.Fragment>
                            }
                        >
                            {event.initiator &&
                                <MenuItem
                                    key="initiator"
                                    value="initiator"
                                    style={getEventStyles(event.name, 'initiator')}
                                >
                                    Initiator
                                </MenuItem>
                            }
                            {allRoles.map((role) => (
                                <MenuItem
                                    key={role}
                                    value={role}
                                    style={getEventStyles(event.name, role)}
                                >
                                    {role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </TableCell>
                <TableCell sx={{ paddingTop: '0.6ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                    <Box sx={{ paddingTop: '1ex', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TextField size="small" sx={{ width: "100%", flexGrow: '1', marginRight: '1ex' }} disabled={!eventValue[event.name]?.enable} value={eventValue[event.name]?.message}
                            onChange={(value) => handleEventMessageChange(value, event)} />
                        <Tooltip title="Edit">
                            <IconButton aria-label="edit" disabled={!eventValue[event.name]?.enable} color="primary" onClick={(he) => handleEventMessageEdit(eventValue[event.name], he)}>
                                <EditNoteIcon size="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    const handleEventMessageEdit = (event, htmlEvent) => {
        setEditEvent(event);
        if ( event.name !== 'enterStep' && event.name !== 'exitStep' ) {
            setExtraVars(PROCESS_VARIABLES);
        }
        setMessageEditOpen(true);
    };

    const handleEditMessageSave = (msg) => {
        setMessageEditOpen(false);
        if ( editEvent ) {
            editEvent.message = msg;
        }
        console.log('Edited message: ' + JSON.stringify(msg));

        const ev = eventValue[editEvent.name];
        const ce = { name: editEvent.name, enable: false, roles: [], message: msg, initiator: false};
        if ( ev ) {
            ce.enable = ev.enable;
            ce.roles = ev.roles;
            ce.initiator = ev.initiator;
        }
        storeEvent(ce);
    }

    const formatMessage = (msg) => {
        /*
        if ( typeof msg === 'string') {
            return '<p>' + msg.replaceAll(/\${(.*?)}/gm,'<strong>$&</strong>') + '</p>'
        }
       
        return '';
         */
        return msg;
    };


    const handleLocksChange = (event) => {
        setLocksMultiple(event.target.checked);
    };

    const handleAddRouteClick = (event) => {
        setAddRouteOpen(true);
    };

    const handleAddRouteCancel = () => {
        setAddRouteOpen(false);
    }

    const handleAddRouteSave = (route) => {
        setAddRouteOpen(false);
        const r = routeData.find(item => {
            if (route.name === item.name && route.name === 'NEXT') {
                return true;
            }
            if (route.name === item.name && route.name === 'STEP') {
                return route.targetSid === item.targetSid;
            }
            if (route.name === item.name && route.name === 'WORKFLOW') {
                return route.targetSid === item.targetSid && route.targetWorkflowId === item.targetWorkflowId;
            }
        });
        if (!r) {
            resolveDestination(route);
            const rd = [...routeData];
            rd.push(route);
            rd.forEach((item, index) => {
                item.id = index;
            });
            // console.log('Added route: ' + JSON.stringify(rd));
            setRouteData(rd);
        }

    };

    const handleMoveUpRouteClick = () => {
        if (selectedRoute > 0) {
            const rd = [...routeData];
            const p = rd[selectedRoute - 1];
            const n = selectedRoute - 1;
            rd[selectedRoute - 1] = rd[selectedRoute];
            rd[selectedRoute - 1].id = selectedRoute - 1;
            rd[selectedRoute] = p;
            rd[selectedRoute].id = selectedRoute;
            setRouteData(rd);
            setSelectedRoute(n);
            setMoveUpRouteEnabled(n > 0);
            setMoveDownRouteEnabled(n >= 0 && n < rd.length - 1);
        }
    };

    const handleMoveDownRouteClick = () => {
        if (selectedRoute >= 0 && selectedRoute < routeData.length - 1) {
            const rd = [...routeData];
            const p = rd[selectedRoute + 1];
            const n = selectedRoute + 1;
            rd[selectedRoute + 1] = rd[selectedRoute];
            rd[selectedRoute + 1].id = selectedRoute + 1;
            rd[selectedRoute] = p;
            rd[selectedRoute].id = selectedRoute;
            setRouteData(rd);
            setSelectedRoute(n);
            setMoveUpRouteEnabled(n > 0);
            setMoveDownRouteEnabled(n >= 0 && n < rd.length - 1);
        }
    };

    const handleDeleteRouteClick = () => {
        if (selectedRoute >= 0) {
            let rd = [...routeData];
            rd.splice(selectedRoute, 1);
            rd.forEach((item, index) => {
                item.id = index;
            });
            setRouteData(rd);
            setSelectedRoute(-1);
            setMoveUpRouteEnabled(false);
            setMoveDownRouteEnabled(false);
            setDeleteRouteEnabled(false);
        }
    };

    const loadActionEvents = (actions) => {
        const ae = [...actionEventData];

    };

    const handleAddActionClick = (event) => {
        setAddActionOpen(true);
    };

    const handleEditActionClick = (event) => {
        if ( selectedActionIndex >= 0 ) {
            const eaction = actionData[selectedActionIndex];
            if ( eaction.name === 'SEND_EMAIL') {
                setEmailActionData(eaction);
                setConfigureEmailOpen(true);
            } else {
                setEditActionOpen(true);
            }

        }
        
    };

    const configureEmailSave = (template) => {
        setConfigureEmailOpen(false);
        const eaction = actionData[selectedActionIndex];
        if ( eaction ) {
            eaction.properties = eaction.properties ? {...eaction.properties, ...template} : template;
        }

    };

    const handleMoveUpActionClick = (event) => {
        if (selectedActionIndex > 0) {
            const ad = [...actionData];
            const p = ad[selectedActionIndex - 1];
            const n = selectedActionIndex - 1;
            ad[selectedActionIndex - 1] = ad[selectedActionIndex];
            ad[selectedActionIndex] = p;
            setActionData(ad);
            setSelectedActionIndex(n);
            setMoveUpActionEnabled(n > 0);
            setMoveDownActionEnabled(n >= 0 && n < ad.length - 1);
        }
    };

    const handleMoveDownActionClick = () => {
        if (selectedActionIndex >= 0 && selectedActionIndex < actionData.length - 1) {
            const ad = [...actionData];
            const p = ad[selectedActionIndex + 1];
            const n = selectedActionIndex + 1;
            ad[selectedActionIndex + 1] = ad[selectedActionIndex];
            ad[selectedActionIndex] = p;
            setActionData(ad);
            setSelectedActionIndex(n);
            setMoveUpActionEnabled(n > 0);
            setMoveDownActionEnabled(n >= 0 && n < ad.length - 1);
        }
    };

    const handleDeleteActionClick = (event) => {
        if (selectedActionIndex >= 0) {
            const daction = actionData[selectedActionIndex];
            // console.log('Deleting action: ' + JSON.stringify(daction));
            let ad = [...actionData];
            ad.splice(selectedActionIndex, 1);
            setActionData(ad);
            if ( daction && Array.isArray(daction.properties?.events)) {
                const ev = {...eventValue};
                let ed = [...eventData];
                daction.properties.events.forEach( e => {
                    console.log('Deleting event: ' + JSON.stringify(e));
                    if ( ev[e.name]) {
                        delete ev[e.name];
                    }
                    const i = ed.findIndex(item => item.name === e.name);
                    if (i >= 0 ) {
                        console.log('Previous event data: ' + JSON.stringify(ed));
                        ed.splice(i, 1);
                        
                    } else {
                        console.log('Event index not found in : ' + JSON.stringify(ed));
                    }
                });
                setEventValue(ev);
                console.log('New event data: ' + JSON.stringify(ed));
                setEventData(ed);
            } else {
                console.log('No events to delete from action: ' + JSON.stringify(daction));
            }
            setSelectedActionIndex(-1);
            setEditActionEnabled(false);
            setDeleteActionEnabled(false);
            setMoveUpActionEnabled(false);
            setMoveDownActionEnabled(false);
        }

    };

    const handleAddActionCancel = (furtherAction) => {
        setAddActionOpen(false);
        if ( furtherAction === 'CLOSEALL') {
            onClose();
        }
    };

    const handleEditActionCancel = (furtherAction) => {
        setEditActionOpen(false);
        if ( furtherAction === 'CLOSEALL') {
            onClose();
        }
    };

    const handleAddActionSave = (action) => {
        setAddActionOpen(false);
        console.log('Chosen action: ' + JSON.stringify(action));
        const ad = [...actionData];
        ad.push(action);
        setActionData(ad);
    };

    const handleEditActionSave = (action) => {
        setEditActionOpen(false);
        // console.log('Chosen action: ' + JSON.stringify(action));
        if (action) {
            const ad = [...actionData];
            ad[selectedActionIndex] = action;
            setActionData(ad);
        }
    };

    const handleActionRowClicked = (event, index) => {
        setSelectedActionIndex(index);
        setEditActionEnabled(index >= 0);
        setDeleteActionEnabled(index >= 0);
        setMoveDownActionEnabled(index >= 0 && index < actionData.length - 1);
        setMoveUpActionEnabled(index > 0);
    };

    const isActionRowSelected = (index) => {
        return index === selectedActionIndex;
    }

    const formatActionProperties = (action) => {
        let f = '';
        for (const p in action) {
            if (p != 'events') {
                let v = action[p];
                if ( p === 'body' ) {
                    const div = document.createElement("div");
                    div.innerHTML = v ? v : '';
                    const rawText = div.innerText;
                    if ( rawText.length > 20 ) {
                        v = rawText.slice(0, 20) + '...';
                    } else {
                        v = rawText;
                    }
                } else if ( p === 'subject') {
                    if ( typeof v === 'string' ) {
                        if ( v.length > 20 ) {
                            v = v.slice(0, 20) + '...';
                        }
                    } else {
                        v = '';
                    }
                } else if ( p === 'xsl' ) {
                    if ( v.label ) {
                        v = v.label;
                    } else {
                        v = '';
                    }
                }
                if (f.length > 0) {
                    f += ', ';
                }
                f += `${p}: ${v}`;
            }
        }
        return f;
    };

    const getStepNumber = (step) => {
        if ( ! step ) {
            return '';
        }
        let n = '';
        if ( step.pid ) {
            const ps = findStep(step.pid);
            if ( ps ) {
                n = `${ps.id + 1}.${step.id}`
            } else {
                n = `${step.pid + 1}.${step.id}`;
            }
        } else {
            n = `${step.id + 1}`;
        }
        return n;
    };


    const getStepLabel = (step) => {
        if ( ! step ) {
            return '';
        }
        if (step && step.name === 'END') {
            if ( step.pid ) {
                return endLabel;
            } else {
                return 'END';
            }
        }
        if (step && step.name === 'START') {
            if ( step.pid ) {
                return startLabel;
            } else {
                return 'START';
            }
        }
        return step.label;
    };

    const findStep = (sid, steps=fullStepList) => {
        const step = steps.find( item => item.sid === sid);
        return step;

    };

    const resolveDestination = (route) => {
        if ( !route ) {
            return;
        }
        const sid = route.targetSid ? route.targetSid : route.targetId;
        switch (route.name) {
            case ('STEP'):
                // route.dest = `${omitStart ? route.targetId : route.targetId + 1}: ${route.targetId >= 0 ? getStepLabel(allSteps[route.targetId]) : ''}`;
                route.dest = `${getStepNumber(findStep(sid))}: ${route.targetId >= 0 ? getStepLabel(findStep(sid)) : ''}`
                break;
            case ('WORKFLOW'):
                let dest = '';
                if (route.targetWorkflowId >= 0) {
                    const w = workflowList.find(item => item.id === route.targetWorkflowId);
                    if (w) {
                        dest = `${dest}${w.label}`;
                        if (sid >= 0 && Array.isArray(w.steps)) {
                            // dest = `${dest} / ${route.targetId + 1}: ${getStepLabel(w.steps[route.targetId])}`;
                            // TODO: w.steps needs to be resolved recursively
                            dest = `${dest} / ${getStepNumber(findStep(sid,w.steps))}: ${getStepLabel(findStep(sid, w.steps))}`;
                        }
                    } else {
                        dest = 'MISSING DESTINATION';
                    }
                }
                route.dest = dest;
                break;
            default:
                route.dest = '';
                break;

        }
    };

    const handleRouteClicked = (event, routeId) => {
        if (selectedRoute === routeId) {
            setSelectedRoute(-1);
            setDeleteRouteEnabled(false);
            setMoveUpRouteEnabled(false);
            setMoveDownRouteEnabled(false);
        } else {
            setSelectedRoute(routeId);
            setDeleteRouteEnabled(true);
            setMoveUpRouteEnabled(routeId > 0);
            setMoveDownRouteEnabled(routeId >= 0 && routeId < routeData.length - 1);
            console.log('routeId = ' + routeId);
        }
    };

    const isRouteSelected = (routeId) => {
        return selectedRoute === routeId;
    }

    const hasReturnRoute = ( route ) => {
        if ( Array.isArray(route.tasks) && route.tasks.length > 0) {
            const rr = route.tasks.find( r => r === 'ADD_RETURN');
            if ( rr ) {
                return true;
            }
        }

        return false;
    };

    const DIALOG_HEIGHT = 975;
    const DIALOG_WIDTH = 1140;

    const getDialogHeight = () => {
        const ws = getWindowDimensions();
        return DIALOG_HEIGHT < ws.height ? DIALOG_HEIGHT : ws.height;
    };

    const getDialogWidth = () => {
        const ws = getWindowDimensions();
        return DIALOG_WIDTH < ws.width ? DIALOG_WIDTH : ws.width;
    };

    const handleFormChange = (event) => {
        setFormValue(event.target.value);
    };



    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: getDialogWidth(),
                    maxWidth: '2048px',
                    maxHeight: '2048px',
                    height: getDialogHeight()
                }
            }}
        >
            <DialogTitle sx={{ fontWeight: 'bold' }}>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '1ex', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ display: 'inline-block', width: '4em', fontWeight: 'bold' }}>Type: </Typography>
                    <Typography sx={typeValue === 'FORM' ? {display: 'inline-block'} : { display: 'inline-block', flexGrow: 1 }}>{typeValue}</Typography>
                    { typeValue === 'FORM' &&
                        <Box sx={{display: 'inline-block', flexGrow: 1, paddingLeft: 6, position: 'relative', top: '-1.2ex'}}>
                            <Box sx={{ display: 'flex', paddingTop: '1.3em', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold', width: '4em' }}>Form:</Typography>
                                <FormControl sx={{ m: 1}} size="small">
                                    <Select
                                        value={formValue}
                                        onChange={handleFormChange}
                                        sx={{ minWidth: '20em', maxWidth: '100%' }}
                                        MenuProps={MenuProps}
                                        
                                    >
                                        {formsList.map((form) => (
                                            <MenuItem
                                                key={form.name}
                                                value={form.name}
                                            >
                                                {form.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    }
                    <Typography sx={{ fontWeight: 'bold', width: '7em', whiteSpace: 'nowrap' }}>Locking:</Typography>
                    <FormControl >
                        <FormControlLabel control={<Switch checked={locksMultiple} onChange={handleLocksChange} />} label={locksMultiple ? 'Multi-user' : 'Single user'} />
                    </FormControl>
                </Box>
                <Box sx={{ paddingTop: '3ex' }}>
                    <TextField label="Name" value={nameValue} onChange={nameChanged} size="small" fullWidth autoFocus={true} readOnly={true}
                        onFocus={event => {
                            event.target.select();
                        }} />
                </Box>
                <Box sx={{ paddingTop: '3ex' }}>
                    <TextField label="Description" value={descriptionValue} onChange={descriptionChanged} size="small"
                        multiline fullWidth
                        rows={2} />
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '1.3em', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'bold', width: '4em' }}>Roles:</Typography>
                    {Array.isArray(rolesValue) ?

                        <FormControl sx={{ m: 1, minWidth: 120, width: 'calc(100% - 3em)' }} size="small">
                            <Select
                                multiple
                                value={rolesValue}
                                onChange={handleRolesChange}
                                sx={{ minWidth: '20em', maxWidth: '100%' }}
                                MenuProps={MenuProps}
                                endAdornment={
                                    <React.Fragment>
                                        <IconButton
                                            size="small"
                                            sx={{ display: rolesValue.length > 0 ? "" : "none" }}
                                            onClick={handleClearClick}>
                                            <ClearIcon fontSize="inherit" />
                                        </IconButton>
                                        <Box sx={{ width: '1em' }}>{' '}</Box>
                                    </React.Fragment>
                                }
                            >
                                {allRoles.map((role) => (
                                    <MenuItem
                                        key={role}
                                        value={role}
                                        style={getStyles(role)}
                                    >
                                        {role}
                                    </MenuItem>
                                ))}
                                {SPECIAL_ROLES.map((role) => (
                                    <MenuItem
                                        key={role}
                                        value={role}
                                        style={getStyles(role)}
                                    >
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        :

                        <Typography sx={{ paddingLeft: '1em' }}>{rolesValue}</Typography>
                    }
                </Box>
                <Box sx={{ paddingTop: '0.5ex', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ paddingTop: '3ex', width: '50%' }}>
                        <Typography sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Actions:</Typography>
                        <Box sx={{ height: '12em', width: '100%', paddingTop: '1ex', paddingRight: '1ex' }}>
                            <TableContainer component={Paper} sx={{ height: '9em' }}>
                                <Table stickyHeader sx={{ minWidth: 350 }} aria-label="action table" size="small">
                                    <TableHead>
                                        <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                            <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold' }}>Name</TableCell>
                                            <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Properties</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {actionData.map((row, index) => (
                                            <TableRow
                                                key={`${row.name}-${index}`}
                                                onClick={(event) => handleActionRowClicked(event, index)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                selected={isActionRowSelected(index)}
                                            >
                                                <TableCell component="th" scope="row" sx={{ width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{formatActionProperties(row.properties)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box sx={{ paddingTop: '0px', paddingLeft: '0px' }}>
                            <Tooltip title="Add Action">
                                <IconButton color="primary" onClick={handleAddActionClick}>
                                    <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit Action">
                                <IconButton color="secondary"  disabled={!editActionEnabled} onClick={handleEditActionClick} >
                                    <SettingsIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Move Up">
                                <IconButton color="inherit" disabled={!moveUpActionEnabled} sx={{ marginLeft: '1ex' }} onClick={handleMoveUpActionClick}>
                                    <MoveUpIcon color="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Move Down">
                                <IconButton color="inherit" disabled={!moveDownActionEnabled} onClick={handleMoveDownActionClick}>
                                    <MoveDownIcon color="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Action">
                                <IconButton disabled={!deleteActionEnabled} onClick={handleDeleteActionClick} color="error" sx={{ marginLeft: '1.5ex' }} >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>

                            <AddActionDialog edit={false} title="Add Action" step={step} stepList={allSteps} open={addActionOpen} onCancel={handleAddActionCancel} onSave={handleAddActionSave}
                                stepIndex={stepIndex} workflowList={workflowList} workflowIndex={workflowIndex} actions={actionData} actionIndex={selectedActionIndex} />
                            <AddActionDialog edit={true} title="Edit Action" step={step} stepList={allSteps} open={editActionOpen} onCancel={handleEditActionCancel} onSave={handleEditActionSave}
                                stepIndex={stepIndex} workflowList={workflowList} workflowIndex={workflowIndex} actions={actionData} actionIndex={selectedActionIndex} />
                            <ConfigureEmailDialog workflowList={workflowList} workflowIndex = {workflowIndex} action={emailActionData} open={configureEmailOpen} 
                                onSave={configureEmailSave} onCancel={() => setConfigureEmailOpen(false)} step={step} actionList={actionList} />
                        </Box>
                    </Box>
                    <Box sx={{ paddingTop: '3ex', width: '50%' }}>
                        <Typography sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', paddingLeft: '1ex' }}>Routing:</Typography>
                        <Box sx={{ height: '12em', width: '100%', paddingTop: '1ex', paddingLeft: '1ex' }}>
                            { /*
                        <DataGrid sx={{height: '14em'}}
                            rows={routeData}
                            columns={routeColumns}
                            pageSize={4}
                            rowsPerPageOptions={[4]}
                            checkboxSelection
                        />
                        */}
                            <TableContainer component={Paper} sx={{ height: '9em' }}>
                                <Table stickyHeader sx={{ minWidth: 350 }} aria-label="routing table" size="small">
                                    <TableHead>
                                        <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                            <TableCell sx={{ paddingRight: '0.2ex',paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold' }}>Type</TableCell>
                                            <TableCell sx={{ paddingLeft: '0.2ex', paddingRight: '0.5ex', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold' }}>Return</TableCell>
                                            <TableCell sx={{ paddingLeft: '0.5ex', paddingRight: '0.5ex',paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Destination</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {routeData.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                onClick={(event) => handleRouteClicked(event, row.id)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                selected={isRouteSelected(row.id)}
                                            >
                                                <TableCell component="th" scope="row" sx={{ width: '8em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', paddingRight: '0.2ex' }}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ width: '6em', paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', paddingLeft: '0.2ex' }}>
                                                    {hasReturnRoute(row) && <CheckIcon/>}
                                                </TableCell>
                                                <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem' }}>{row.dest}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box sx={{ paddingTop: '0px', paddingLeft: '1ex' }}>
                            <Tooltip title="Add Route">
                                <IconButton color="primary" onClick={handleAddRouteClick}>
                                    <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Move Up">
                                <IconButton color="inherit" disabled={!moveUpRouteEnabled} sx={{ marginLeft: '1ex' }} onClick={handleMoveUpRouteClick}>
                                    <MoveUpIcon color="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Move Down">
                                <IconButton color="inherit" disabled={!moveDownRouteEnabled} onClick={handleMoveDownRouteClick}>
                                    <MoveDownIcon color="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Route">
                                <IconButton disabled={!deleteRouteEnabled} onClick={handleDeleteRouteClick} color="error" sx={{ marginLeft: '1.5ex' }} >
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            <AddRouteDialog step={step} stepList={allSteps} open={addRouteOpen} onCancel={handleAddRouteCancel} onSave={handleAddRouteSave}
                                stepIndex={stepIndex} workflowList={workflowList} workflowIndex={workflowIndex} routes={routeData}
                                omitStart={omitStart} endLabel={endLabel} />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ paddingTop: '3ex'}}>
                    <Typography sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', paddingLeft: '1ex' }}>Notifications:</Typography>
                </Box>
                <Box sx={{ paddingTop: '1ex' }}>
                    <TableContainer component={Paper} sx={{ height: '12em' }}>
                        <Table stickyHeader sx={{ minWidth: 350 }} aria-label="notifications table" size="small">
                            <TableHead>
                                <TableRow sx={{ paddingTop: 0, paddingBotton: 0 }}>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '8em', fontWeight: 'bold' }}>Event</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', width: '4em', fontWeight: 'bold' }}>Enable</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Roles</TableCell>
                                    <TableCell sx={{ paddingTop: '1.5ex', paddingBottonm: 0, lineHeight: '0.9rem', fontWeight: 'bold' }}>Message</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {STEP_EVENTS.map((event, index) => makeEventRow(event,index))}
                                {Array.isArray(actionData) && actionData.map( (action,index) => {
                                    return Array.isArray(action.properties?.events) && (
                                        action.properties.events.map( (event,eindex) => makeEventRow(event,eindex))
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <EditMessageDialog open={messageEditOpen} message={formatMessage(editEvent?.message)} onCancel={(e) => setMessageEditOpen(false)} 
                    eventName={editEvent?.name} onSave={handleEditMessageSave} extraVars={extraVars} step={step} />
            </DialogContent>
            <DialogActions sx={{ paddingRight: 'calc(8px + 2ex)', paddingBlock: 'calc(8px + 1ex)' }}>
                <Button variant="outlined" onClick={onOKClick} sx={{ minWidth: '7em' }}>OK</Button>
                <Button variant="outlined" onClick={onCancelClick} sx={{ minWidth: '7em' }}>Cancel</Button>
            </DialogActions>
        </Dialog>

    );

}

function SubAttributesEdit(props) {
    const {
        def,
        allRoles,
        readOnly,
        onChange,
        onSelection,
        saveEnabled,
        refresh,
        update,
        workflowIndex,
        workflowList,
        stepIndex,
        pindex, // parent index label '1' or '1.2' 
        parentSelectionIndex,
        ...other
    } = props;

    const [callStep, setCallStep] = React.useState(null);
    const [subdef, setSubdef] = React.useState({});
    const [substeps, setSubsteps] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState(-1);
    // const [allRoles, setAllRoles] = React.useState([]);
    const [stepRoles, setStepRoles] = React.useState([]); // items are also arrays
    const [contextMenu, setContextMenu] = React.useState(null);
    const [stepEditEnabled, setStepEditEnabled] = React.useState(false);
    const [undoSaveEnabled, setUndoSaveEnabled] = React.useState(false);

    const [dialogTitle, setDialogTitle] = React.useState('Edit Step');
    const [dialogStep, setDialogStep] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [stepDefLabels, setStepDefLabels] = React.useState({});

    const [stepDeleteOpen, setStepDeleteOpen] = useState(false);
    const [stepDeleteEnabled, setStepDeleteEnabled] = useState(false);
    const [stepDeleteName, setStepDeleteName] = useState('');
    const [stepDeleteWorkflowName, setStepDeleteWorkflowName] = useState('');

    // expanded subroutines
    const [expanded, setExpanded] = React.useState([]);
    const [fullStepList, setFullStepList] = React.useState([]);
    const [actionEvents, setActionEvents] = React.useState([]);

    const endLabel = 'EXIT';

    React.useEffect(() => {

        setEditIndex(-1);
        setStepEditEnabled(false);
        if (def) {
            // setPublishWorkflow(def.label);
            setStepDeleteWorkflowName(def.label);
            if (Array.isArray(def.steps)) {
                // this is the call step
                const cstep = def.steps[stepIndex];
                // console.log('cstep: ' + JSON.stringify(cstep));
                setCallStep(cstep);
                if (cstep.properties) {
                    const ssteps = cstep.properties.substeps;
                    setSubsteps(ssteps);

                    let sroles = [];
                    if (Array.isArray(ssteps)) {
                        ssteps.forEach(step => {
                            if (Array.isArray(step.roles)) {
                                sroles.push(step.roles);
                            } else {
                                sroles.push([]);
                            }
                        });
                    }
                    setStepRoles(sroles);

                    let fsl = [];
                    if ( def.steps?.length > 0 ) {
                        fsl.push(def.steps[0]);
                    }
                    computeFullStepList(fsl, def.steps);
                    setFullStepList(fsl);

                    const targetDef = cstep.properties.target;
                    netGet('/api/workflow/def/list?categ=MODEL_SUB')
                        .then(response => response.json())
                        .then(data => {
                            if (data && Array.isArray(data)) {
                                const cdef = data.find(item => item.name === targetDef);
                                // console.log('Found def ' + targetDef + ' : ' + cdef.steps.length);
                                if (cdef) {
                                    setSubdef(cdef);
                                }
                            }
                        });
                }
            }
        }
    }, [def]);

    /*
    React.useEffect( () => {
        setUndoSaveEnabled(saveEnabled);
    }, [saveEnabled]);
    */


    React.useEffect(() => {
        netGet('/api/workflow/step/def/list?mask=0')
            .then(response => response.json())
            .then(steps => {
                if (Array.isArray(steps)) {
                    let labels = {};
                    steps.forEach(item => {
                        if (item.name === 'END') {
                            labels[item.name] = endLabel;
                        } else {
                            labels[item.name] = item.label;
                        }
                    });
                    setStepDefLabels(labels);
                }
            }).catch(error => {
                console.log('Error fetching step definition list: ' + error);
            });
        netGet('/api/workflow/action/list')
            .then(response => response.json())
            .then((data) => {
                // console.log('Actions: ' + JSON.stringify(data));
                if (Array.isArray(data) && data.length > 0) {
                    const ae = [];
                    data.forEach(item => {
                        if (Array.isArray(item.properties?.events)) {
                            item.properties.events.forEach(e => ae.push(e));
                        }
                    });
                    setActionEvents(ae);
                }
            });
    }, []);

    const computeFullStepList = (fsl, steps) => {
        if ( Array.isArray(steps) ) {
            steps.forEach( step => {
                if ( step.id > 0 ) {
                    fsl.push(step);
                    if ( step.name === 'CALL' ) {
                        if ( step.properties?.substeps ) {
                            computeFullStepList(fsl, step.properties.substeps);
                        }
                    }
                }
            });

        }
        return fsl;

    };

    const findStep = (sid, steps=fullStepList) => {
        const step = steps.find( item => item.sid ? item.sid === sid : item.id === sid);
        return step;

    };

    const isLockedByMe = () => {
        return def && typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const handleStepDeleteAction = () => {
        handleContextMenuClose();
        setDialogOpen(false);
        const sstep = substeps[editIndex];
        if ( sstep ) {
            setStepDeleteName(sstep.label);
            setStepDeleteOpen(true);
        }
    };

    const handleStepEditAction = () => {
        handleContextMenuClose();
        setDialogOpen(true);
        // console.log('Substeps editing...');
    };

    const handleEditClick = (index) => {
        if (isLockedByMe()) {
            setEditIndex(index);
            setDialogStep(substeps[index]);
            setStepEditEnabled(true);
            
        } else {
            setEditIndex(-1);
            setDialogStep(null);
            setStepEditEnabled(false);
        }
    };

    const handleContextMenu = (event, index) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        if (index >= 0) {
            handleEditClick(index);
        }
    };


    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleDialogSave = (name, description, roles, multiLock, routes, actions, events, target) => {
        setDialogOpen(false);
        if (editIndex >= 0) {
            if (!def.saveSteps) {
                // save a copy just in case
                def.saveSteps = [...def.steps];
            }
            let step = { ...substeps[editIndex] };
            step.label = name;
            step.description = description;
            step.roles = roles;
            step.routes = routes;
            stepRoles[editIndex] = roles;
            if (!step.properties) {
                step.properties = {};
            }
            step.properties.multiLock = multiLock;
            step.properties.actions = actions;
            step.properties.events = events;
            if ( target ) {
                step.properties.target = target;
            }
            substeps[editIndex] = step;
            // setUndoSaveEnabled(true); // not necessary to enable, an event should come back in response to onChange
            // saveToServer(def); // don't save here, hold it 

            if (typeof onChange === 'function') {
                onChange(stepIndex);
            }

        }
    };

    const handleDialogCancel = () => {
        setDialogOpen(false);
    };

    const undoSaveHandler = () => {
        handleContextMenuClose();
        if (typeof def.saveSteps !== 'undefined') {
            // put back the steps
            def.steps = [...def.saveSteps];
            delete def.saveSteps;
            // report we have no changes
            if (typeof onChange === 'function') {
                onChange(false, workflowIndex);
            }
        }
    };

    const getStepNumber = (step) => {
        if ( ! step ) {
            return '';
        }
        let n = '';
        if ( step.pid ) {
            const ps = findStep(step.pid);
            if ( ps ) {
                n = `${ps.id+1}.${step.id}`;
            } else {
                n = `${step.pid+1}.${step.id}`;
            }
        } else {
            n = `${step.id + 1}`;
        }
        return n;
    };

    const getStepLabel = (step) => {
        if ( ! step ) {
            return '';
        }
        if (step && step.name === 'END') {
            if ( step.pid ) {
                return endLabel;
            } else {
                return 'END';
            }
        }
        return step.label;
    };

    const resolveDestination = (route) => {

        let r = route ? { ...route } : { dest: 'NEXT STEP' };
        if (route) {
            // console.log(JSON.stringify(r) + ' : ' + JSON.stringify(def.steps[r.targetId]));
            r.dest = 'NEXT STEP';
            const sid = r.targetSid ? r.targetSid : r.targetId;
            switch (r.name) {
                case ('STEP'):
                    // r.dest = `STEP ${pindex}.${r.targetId}: ${r.targetId >= 0 ? (substeps && substeps[r.targetId] ? getStepLabel(substeps[r.targetId]) : '') : ''}`;
                    r.dest = `STEP ${getStepNumber(findStep(sid))}: ${getStepLabel(findStep(sid))}`;
                    break;
                case ('WORKFLOW'):
                    let dest = '';
                    if (route.targetWorkflowId >= 0) {
                        const w = workflowList.find(item => item.id === r.targetWorkflowId);
                        if (w) {
                            dest = `${dest}${w.label}`;

                            if (r.targetId >= 0 && Array.isArray(w.steps)) {
                                dest = `${dest} / ${r.targetId + 1}: ${getStepLabel(w.steps[r.targetId])}`;
                            }
                        } else {
                            dest = 'MISSING DESTINATION';
                        }
                    }
                    r.dest = dest;
                    break;
                default:
                    if (substeps && substeps[r.targetId] && substeps[r.targetId].name !== 'END') {
                        r.dest = 'NEXT STEP';
                        // console.log('NEXT: ' + JSON.stringify(r));
                    }
                    break;

            }
        }
        return r;
    };

    const handleItemClick = (index) => {
        if (!readOnly) {
            setEditIndex(index);
            setDialogStep(substeps[index]);
            setStepEditEnabled(true);
            setStepEditEnabled(true);
            if (typeof onSelection === 'function') {
                onSelection(stepIndex, index);
            }
        } else {
            setEditIndex(-1);
            setDialogStep(null);
            setStepEditEnabled(false);
            setStepDeleteEnabled(false);
        }
    };

    const resolveEvent = (event, prefix='') => {
        if ( typeof event.enable === 'boolean' && event.enable ) {
            
            let label = getEventLabel(event.name);
            if ( !label || label == '') {
                const av = actionEvents.find(e => e.name === event.name);
                if (av) {
                    label = av.label;
                }
            }
            // console.log('resolving event: ' + JSON.stringify(event) + ' = ' + label);
            return prefix + label;
        }
        return '';
    };

    const deleteStepCancel = () => {
        setStepDeleteOpen(false);
    }

    const deleteStepSave = () => {
        setStepDeleteOpen(false);
        const dstep = substeps[editIndex];
        // console.log('DELETING STEP ' + JSON.stringify(dstep));
        console.log('DELETING STEP ' + dstep.sid + ' : ' + dstep.label);
        if ( dstep ) {
            deleteStep(dstep.sid, def.steps);
            let fsl = [];
            if (def.steps?.length > 0) {
                fsl.push(def.steps[0]);
            }
            computeFullStepList(fsl, def.steps);
            setFullStepList(fsl);
            if ( typeof onChange === 'function') {
                onChange(editIndex);
            }
        }
    };

    const deleteStep = (sid, steps) => {
        if ( Array.isArray(steps) ) {
            const ti = steps.findIndex( item => item.sid === sid);
            let tid = -1;
            if ( ti >= 0 && ti < steps.length ) {
                const tstep = steps.find( item => item.sid === sid);
                tid = tstep?.id;
                steps.splice(ti, 1);
            }
            steps.forEach(step => {
                if ( Array.isArray(step.routes) ) {
                    step.routes.forEach( r => {
                        if ( tid >= 0 ) {
                            if ( r.targetId > tid ) {
                                r.targetId = r.targetId - 1;
                            }
                        }
                        if ( r.targetSid > sid) {
                            r.targetSid = r.targetSid - 1;
                        }
                    });
                }
                // some actions also need adjustment
                if ( Array.isArray(step.properties?.actions) ) {
                    step.properties.actions.forEach( action => {
                        if ( action.name === 'DISABLE_ROUTE') {
                            if ( action.properties?.targetStepId && action.properties.targetStepId > sid) {
                                action.properties.targetStepId = action.properties.targetStepId - 1;
                            }
                            if ( action.properties?.targetRouteId && action.properties.targetRouteId > sid) {
                                action.properties.targetRouteId = action.properties.targetRouteId - 1;
                            }
                        }

                    });
                }
                if (step.name === 'CALL') {
                    if (step.properties?.substeps) {
                        deleteStep(sid, step.properties.substeps);
                    }
                }
            });

        }
    };



    return (
        <List sx={{ paddingLeft: '50px' }}>
            {substeps &&
                substeps.map((substep, subindex) => {
                    return substep.name !== 'START' ?
                        <ListItem key={`WISS-${pindex}-${substep.id}`} alignItems="flex-start" sx={{ display: 'block' }}
                        >
                            <ListItemButton alignItems="flex-start" onClick={() => handleItemClick(subindex)}
                                selected={subindex === editIndex && !readOnly && parentSelectionIndex === -1}
                                onContextMenu={(event) => handleContextMenu(event, subindex)} >
                                <ListItemAvatar>
                                    <Avatar sx={{ width: 40, height: 24 }}>
                                        <Typography>{pindex}.{subindex}</Typography>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText disableTypography={true}
                                    primary={
                                        <Typography sx={{ fontWeight: 'bold' }} >{getStepLabel(substep)}</Typography>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '1ex', paddingBottom: '1ex' }}>{stepDefLabels[substep.name] ? stepDefLabels[substep.name] : substep.name}</Typography>
                                            <Typography sx={{ fontSize: '14px' }}>{substep.description}</Typography>
                                            <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Roles: </Typography>
                                                <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                    {Array.isArray(substep.roles) && substep.roles.length > 0 ?
                                                        substep.roles.sort().reduce((total, role) => total.length > 0 ? total + ', ' + role : role) :
                                                        ''
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Locking: </Typography>
                                                <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                    {substep.properties && substep.properties.multiLock ? 'Multi-user' : 'Single user'
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Routes: </Typography>
                                                <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                    {substep && Array.isArray(substep.routes) && substep.routes.length > 0 ?
                                                        substep.routes.map(r => resolveDestination(r)).reduce((total, route) => typeof total === 'string' && total.length > 0 ? total + ',\u00a0 ' + route.dest : route.dest, '') :
                                                        (substep && substep.name !== 'END' ? 'NEXT STEP' : '')
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Actions: </Typography>
                                                <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                    {substep && Array.isArray(substep.properties?.actions) && substep.properties.actions.length > 0 ?
                                                        substep.properties.actions.reduce((total, action) => typeof total === 'string' && total.length > 0 ? total + ',\u00a0' + action.label : action.label, '') :
                                                        ''
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                <Typography sx={{ fontWeight: 'bold', width: '7em' }}>Notifications: </Typography>
                                                <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                    {substep && Array.isArray(substep.properties?.events) && substep.properties.events.length > 0 ?
                                                        substep.properties.events.reduce((total, event) => typeof total === 'string' && total.length > 0 ? total + resolveEvent(event, ',\u00a0') : resolveEvent(event), '') :
                                                        ''
                                                    }
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    } />
                            </ListItemButton>
                        </ListItem> : null
                }
                )
            }
            <Menu
                open={contextMenu !== null}
                onClose={handleContextMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={handleStepEditAction} disabled={readOnly /* !stepEditEnabled */}>Edit...</MenuItem>
                <MenuItem onClick={handleStepDeleteAction} disabled={readOnly /* !stepEditEnabled */}>Delete...</MenuItem>
                <MenuItem onClick={undoSaveHandler} disabled={!undoSaveEnabled}>Undo</MenuItem>
            </Menu>
            <EditStepDialog open={dialogOpen} title={dialogTitle} step={dialogStep} stepIndex={editIndex}
                allSteps={substeps} allRoles={allRoles} omitStart={true} endLabel={endLabel}
                onSave={handleDialogSave} onCancel={handleDialogCancel}
                workflowList={workflowList} workflowIndex={workflowIndex} />
            <StepDeleteDialog title="Delete Step" open={stepDeleteOpen} stepName={stepDeleteName} workflowName={stepDeleteWorkflowName}
                        onSave={deleteStepSave} onCancel={deleteStepCancel} />
        </List>
    );
}

function WorkflowDefinitionAttributesEdit(props) {
    const {
        def,
        onChange,
        onSelection,
        onInsert,
        onDelete,
        saveEnabled,
        refresh,
        update,
        workflowIndex,
        workflowList,
        insertAfterEnabled,
        insertBeforeEnabled,
        deleteStepEnabled,
        ...other
    } = props;
    // const [ workflowUpdate, setWorkflowUpdate] = React.useState(0); // increment to update
    const [editIndex, setEditIndex] = React.useState(-1);
    const [allRoles, setAllRoles] = React.useState([]);
    const [stepRoles, setStepRoles] = React.useState([]); // items are also arrays
    const [stepEditEnabled, setStepEditEnabled] = React.useState(false);
    // const [insertBeforeEnabled, setInsertBeforeEnabled] = React.useState(false);
    // const [insertAfterEnabled, setInsertAfterEnabled] = React.useState(false);
    const [undoSaveEnabled, setUndoSaveEnabled] = React.useState(false);
    const [publishEnabled, setPublishEnabled] = React.useState(false);
    const [contextMenu, setContextMenu] = React.useState(null);

    const [dialogTitle, setDialogTitle] = React.useState('Edit Step');
    const [dialogStep, setDialogStep] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [publishDialogTitle, setPublishDialogTitle] = React.useState('Publish Workflow');
    const [publishWorkflow, setPublishWorkflow] = React.useState('');
    const [publishOpen, setPublishOpen] = React.useState(false);
    const [stepDefLabels, setStepDefLabels] = React.useState({});
    // expanded subroutines
    const [expanded, setExpanded] = React.useState([]);

    const [modified, setModified] = React.useState(false);
    const [fullStepList, setFullStepList] = React.useState([]);
    const [actionEvents, setActionEvents] = React.useState([]);


    const theme = useTheme();
    const endLabel = 'END';

    /*
    React.useEffect(() => {
        if (instance) {
            console.log('Fetching workflow definition ' + instance.definitionId + '...');

            netGet('/api/workflow/def/' + instance.definitionId)
                .then(response => response.json())
                .then(data => {
                    // console.log(Array.isArray(data.steps) + ' : ' + data.steps.length);
                    setWorkflowDef(data);
                    /
                });
        }
        
    }, [workflowUpdate, instance, update]); 
      */

    React.useEffect(() => {
        netGet('/api/auth/roles')
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data)) {
                    // console.log('Roles: ' + data.length);
                    setAllRoles(data);
                }
            }).catch(error => console.log('Failed to retrieve roles: ' + error));
        setEditIndex(-1);
        setStepEditEnabled(false);
        setPublishEnabled(canPublish());
        if (def) {
            setPublishWorkflow(def.label);
            if (Array.isArray(def.steps)) {
                let sroles = [];
                def.steps.forEach(step => {
                    if (Array.isArray(step.roles)) {
                        sroles.push(step.roles);
                    } else {
                        sroles.push([]);
                    }
                });
                setStepRoles(sroles);
                let fsl = [];
                if ( def.steps?.length > 0 ) {
                    fsl.push(def.steps[0]);
                }
                computeFullStepList(fsl, def.steps);
                setFullStepList(fsl);
                // TODO: update after inserting/removing steps
            }
        }
    }, [def]);

    React.useEffect(() => {
        setUndoSaveEnabled(saveEnabled);
    }, [saveEnabled]);

    React.useEffect(() => {
        netGet('/api/workflow/step/def/list?mask=0')
            .then(response => response.json())
            .then(steps => {
                if (Array.isArray(steps)) {
                    let labels = {};
                    steps.forEach(item => {
                        labels[item.name] = item.label;
                    });
                    setStepDefLabels(labels);
                }
            }).catch(error => {
                console.log('Error fetching step definition list: ' + error);
            });
        netGet('/api/workflow/action/list')
            .then(response => response.json())
            .then((data) => {
                // console.log('Actions: ' + JSON.stringify(data));
                if (Array.isArray(data) && data.length > 0) {
                    const ae = [];
                    data.forEach(item => {
                        if (Array.isArray(item.properties?.events)) {
                            item.properties.events.forEach(e => ae.push(e));
                        }
                    });
                    setActionEvents(ae);
                }
            });
    }, []);

    const computeFullStepList = (fsl, steps) => {
        if ( Array.isArray(steps) ) {
            steps.forEach( step => {
                if ( step.id > 0 ) {
                    fsl.push(step);
                    if ( step.name === 'CALL' ) {
                        if ( step.properties?.substeps ) {
                            computeFullStepList(fsl, step.properties.substeps);
                        }
                    }
                }
            });

        }
        return fsl;

    };

    const findStep = (sid, steps=fullStepList) => {
        const step = steps.find( item => item.sid ? item.sid === sid : item.id === sid);
        return step;

    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const canPublish = () => {
        if (!def) {
            return false;
        }
        return (((def.flag & 6) == 0)); // not locked or published
    };

    const handleEditClick = (index) => {
        if (isLockedByMe()) {
            setEditIndex(index);
            setDialogStep(def.steps[index]);
            setStepEditEnabled(true);
        } else {
            setEditIndex(-1);
            setDialogStep(null);
            setStepEditEnabled(false);
        }
        if (typeof onSelection === 'function') {
            onSelection(def.steps, index);
        }
    };

    const publishToServer = (def) => {
        netPost('/api/workflow/def/publish', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not publish workflow definition: status = ' + resp.status);
                } else {
                    if (refresh) {
                        refresh(def);
                    }
                }
            });
    };

    const handleSaveClick = (index) => {
        let sroles = stepRoles.map((x) => x.map((r) => r));
        if (typeof def.steps[index] !== 'undefined') {
            def.steps[index].roles = sroles[index];
            saveToServer(def);
            if (onChange) {
                onChange(def);
            }
        }
        setEditIndex(-1);
    };



    const isLockedByMe = () => {
        return def && typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const handleStepEditAction = () => {
        handleContextMenuClose();
        setDialogOpen(true);
    };

    const handleInsertAfterAction = () => {
        handleContextMenuClose();
        if (typeof onInsert === 'function') {
            onInsert(1);
        }
    };

    const handleInsertBeforeAction = () => {
        handleContextMenuClose();
        if (typeof onInsert === 'function') {
            onInsert(0);
        }
    };

    const deleteStepHandler = () => {
        handleContextMenuClose();
        if (typeof onDelete === 'function') {
            onDelete();
        }
    };

    const handlePublishAction = () => {
        handleContextMenuClose();
        setPublishOpen(true);
    };

    const handlePublishSave = () => {
        setPublishOpen(false);
        publishToServer(def);
    };

    const handlePublishCancel = () => {
        setPublishOpen(false);
    };

    const saveToServer = (def) => {
        netPost('/api/workflow/def/update', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not save workflow definition: status = ' + resp.status);
                } else {
                    if (refresh) {
                        refresh(def);
                    }
                }
            });
    };

    const handleContextMenu = (event, index) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        if (index >= 0) {
            handleEditClick(index);
        }
    };


    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleDialogSave = (name, description, roles, multiLock, routes, actions, events, target) => {
        setDialogOpen(false);
        if (editIndex >= 0) {
            if (!def.saveSteps) {
                // save a copy just in case
                def.saveSteps = [...def.steps];
            }
            let step = { ...def.steps[editIndex] };
            step.label = name;
            step.description = description;
            step.roles = roles;
            step.routes = routes;
            stepRoles[editIndex] = roles;
            if (!step.properties) {
                step.properties = {};
            }
            step.properties.multiLock = multiLock;
            step.properties.actions = actions;
            step.properties.events = events;
            if ( target ) {
                step.properties.target = target;
            }
            def.steps[editIndex] = step;
            // setUndoSaveEnabled(true); // not necessary to enable, an event should come back in response to onChange
            // saveToServer(def); // don't save here, hold it 
            if (typeof onChange === 'function') {
                onChange(true, workflowIndex);
            }
        }
    };

    const handleDialogCancel = () => {
        setDialogOpen(false);
    };

    const undoSaveHandler = () => {
        handleContextMenuClose();
        if (typeof def.saveSteps !== 'undefined') {
            // put back the steps
            def.steps = [...def.saveSteps];
            delete def.saveSteps;
            // report we have no changes
            if (typeof onChange === 'function') {
                onChange(false, workflowIndex);
            }
        }
    };

    const getStepNumber = (step) => {
        if ( ! step ) {
            return '';
        }
        let n = '';
        if ( step.pid ) {
            const ps = findStep(step.pid);
            if ( ps ) {
                n = `${ps.id+1}.${step.id}`;
            } else {
                n = `${step.pid+1}.${step.id}`;
            }
        } else {
            n = `${step.id + 1}`;
        }
        return n;
    };

    const getStepLabel = (step) => {
        if ( ! step ) {
            return '';
        }
        if (step && step.name === 'END') {
            if ( step.pid ) {
                return endLabel;
            } else {
                return 'END';
            }
        }
        return step.label;
    };

    const resolveDestination = (route) => {

        let r = route ? { ...route } : { dest: 'NEXT STEP' };
        if (route) {
            // console.log(JSON.stringify(r) + ' : ' + JSON.stringify(def.steps[r.targetId]));
            // console.log('resolveDestination1: ' + JSON.stringify(r));
            r.dest = 'NEXT STEP';
            const sid = r.targetSid ? r.targetSid : r.targetId;
            switch (r.name) {
                case ('STEP'):
                    // r.dest = `STEP ${r.targetId + 1}: ${r.targetId >= 0 ? (def && def.steps && def.steps[r.targetId] ? def.steps[r.targetId].label : '') : ''}`;
                    r.dest = `STEP ${getStepNumber(findStep(sid))}: ${getStepLabel(findStep(sid))}`;
                    // console.log('sid1: ' + sid + ' : ' + JSON.stringify(findStep(sid)));
                    break;
                case ('WORKFLOW'):
                    let dest = '';
                    if (route.targetWorkflowId >= 0) {
                        const w = workflowList.find(item => item.id === r.targetWorkflowId);
                        if (w) {
                            dest = `${dest}${w.label}`;

                            if (r.targetId >= 0 && Array.isArray(w.steps)) {
                                dest = `${dest} / ${r.targetId + 1}: ${w.steps[r.targetId].label}`;
                            }
                        } else {
                            dest = 'MISSING DESTINATION';
                        }
                    }
                    r.dest = dest;
                    break;
                default:
                    if (def && def.steps && def.steps[r.targetId] && def.steps[r.targetId].name !== 'END') {
                        r.dest = 'NEXT STEP';
                        // console.log('NEXT: ' + JSON.stringify(r));
                    }
                    break;

            }
        }
        return r;
    };

    const toggleCallItem = (index) => {
        // TODO: handle more than one index
        let ee = [...expanded];
        if (ee.includes(index)) {
            ee = [];
        } else {
            ee.push(index);
        }
        setExpanded(ee);
    };

    const handleSubstepsSelection = (index, subindex) => {
        setEditIndex(-1);
        if (typeof onSelection === 'function') {
            onSelection(def.steps, -1);
        }
    };

    const handleSubstepChange = (index) => {
        setDialogOpen(false);
        if (typeof onChange === 'function') {
            onChange(true, workflowIndex);
        }
    };

    const resolveEvent = (event, prefix='') => {
        if ( typeof event.enable === 'boolean' && event.enable ) {
            let label = getEventLabel(event.name);
            if ( !label || label == '') {
                const av = actionEvents.find(e => e.name === event.name);
                if (av) {
                    label = av.label;
                }
            }
            return prefix + label;
        }
        return '';
    };

    return (
        def && Array.isArray(def.steps) && def.steps.length > 0 ? (
            <Box sx={{ display: 'grid', gridTemplateRows: '1fr min-content', width: '100%', height: '100%', padding: '1ex' }}>
                <Box sx={{ width: '100%', height: '100%', position: 'relative', overflowY: 'auto', padding: '1ex', paddingTop: '2ex', minHeight: '3em' }}
                >
                    <EditIndicator readOnly={!isLockedByMe()} />
                    <Typography sx={{ fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>{def.label}</Typography>
                    <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '1ex' }}>WORKFLOW</Typography>
                    <Typography sx={{ paddingTop: '1ex', paddingBottom: '2ex' , whiteSpace: 'pre-wrap'}}>{def.description}</Typography>
                    <List >
                        {
                            def.steps.map((step, index) => (
                                <ListItem key={'WIS' + step.id} alignItems="flex-start" sx={{ display: 'block' }}
                                >
                                    <ListItemButton onClick={() => handleEditClick(index)} alignItems="flex-start" selected={editIndex === index && isLockedByMe()}
                                        onContextMenu={(event) => handleContextMenu(event, index)} >
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 24, height: 24 }}>
                                                <Typography>{index + 1}</Typography>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText disableTypography={true}
                                            primary={
                                                <Typography sx={{ fontWeight: 'bold' }} >{step.label}</Typography>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Typography sx={{ fontSize: '12px', color: 'gray', paddingTop: '1ex', paddingBottom: '1ex' }}>{stepDefLabels[step.name] ? stepDefLabels[step.name] : step.name}</Typography>
                                                    <Typography sx={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>{step.description}</Typography>
                                                    <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Roles: </Typography>
                                                        <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                            {Array.isArray(step.roles) && step.roles.length > 0 ?
                                                                step.roles.sort().reduce((total, role) => total.length > 0 ? total + ', ' + role : role) :
                                                                ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Locking: </Typography>
                                                        <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                            {step.properties && step.properties.multiLock ? 'Multi-user' : 'Single user'
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Routes: </Typography>
                                                        <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                            {step && Array.isArray(step.routes) && step.routes.length > 0 ?
                                                                step.routes.map(r => resolveDestination(r)).reduce((total, route) => typeof total === 'string' && total.length > 0 ? total + ',\u00a0 ' + route.dest : route.dest, '') :
                                                                (step && step.name !== 'END' ? 'NEXT STEP' : '')
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '5em' }}>Actions: </Typography>
                                                        <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                            {step && Array.isArray(step.properties?.actions) && step.properties.actions.length > 0 ?
                                                                step.properties.actions.reduce((total, action) => typeof total === 'string' && total.length > 0 ? total + ',\u00a0' + action.label : action.label, '') :
                                                                ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ paddingTop: '1ex', display: 'flex', alignItems: 'baseline' }}>
                                                        <Typography sx={{ fontWeight: 'bold', width: '7em' }}>Notifications: </Typography>
                                                        <Typography sx={{ paddingLeft: '1ex', paddingRight: '1ex' }}>
                                                            {step && Array.isArray(step.properties?.events) && step.properties.events.length > 0 ?
                                                                step.properties.events.reduce((total, event) => typeof total === 'string' && total.length > 0 ? total + resolveEvent(event,',\u00a0') : resolveEvent(event), '') :
                                                                ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                        />
                                        {step.name === 'CALL' &&
                                            <IconButton onClick={() => toggleCallItem(index)}>
                                                {expanded.includes(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>}
                                    </ListItemButton>
                                    {step.name === 'CALL' &&

                                        <Collapse in={expanded.includes(index)} timeout="auto" unmountOnExit >
                                            <SubAttributesEdit def={def} workflowList={workflowList} workflowIndex={workflowIndex}
                                                stepIndex={index} readOnly={!isLockedByMe()} pindex={`${index + 1}`} allRoles={allRoles}
                                                parentSelectionIndex={editIndex} onSelection={handleSubstepsSelection} onChange={handleSubstepChange} />
                                        </Collapse>
                                    }
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
                <Box sx={{ padding: '1ex', paddingRight: '2ex', whiteSpace: 'nowrap', overflowX: 'hidden', display: 'flex', justifyContent: 'space-between', width: '100%' }} color={theme.palette.primary.main}>
                    <Box>
                        <Tooltip title="Insert Before">
                            <IconButton color="inherit" disabled={!insertBeforeEnabled} onClick={handleInsertBeforeAction}>
                                <InsertBeforeIcon color="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Insert After">
                            <IconButton color="inherit" disabled={!insertAfterEnabled} onClick={handleInsertAfterAction}>
                                <InsertAfterIcon color="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Step">
                            <IconButton color="inherit" disabled={!stepEditEnabled} onClick={handleStepEditAction}>
                                <AutoFixNormalIcon color="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Step">
                            <IconButton color="inherit" disabled={!deleteStepEnabled} sx={{ marginLeft: '0.5em' }} onClick={deleteStepHandler}>
                                <DeleteIcon color="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Undo Modifications">
                            <IconButton color="inherit" disabled={!undoSaveEnabled} onClick={undoSaveHandler}>
                                <UndoIcon color="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Tooltip title="Publish This Workflow">
                        <Button variant="outlined" startIcon={<PublishedWithChangesIcon fontSize="inherit" />} disabled={!publishEnabled} onClick={handlePublishAction}>
                            Publish
                        </Button>
                    </Tooltip>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleInsertBeforeAction} disabled={!insertBeforeEnabled}>Insert Before...</MenuItem>
                        <MenuItem onClick={handleInsertAfterAction} disabled={!insertAfterEnabled}>Insert After...</MenuItem>
                        <MenuItem onClick={handleStepEditAction} disabled={!stepEditEnabled}>Edit...</MenuItem>
                        <MenuItem onClick={deleteStepHandler} disabled={!deleteStepEnabled}>Delete...</MenuItem>
                        <MenuItem onClick={undoSaveHandler} disabled={!undoSaveEnabled}>Undo</MenuItem>
                    </Menu>
                    <EditStepDialog open={dialogOpen} title={dialogTitle} step={dialogStep} stepIndex={editIndex} allSteps={def.steps} allRoles={allRoles} onSave={handleDialogSave} onCancel={handleDialogCancel}
                        workflowList={workflowList} workflowIndex={workflowIndex} />
                    <WorkflowPublishDialog open={publishOpen} title={publishDialogTitle} workflowName={publishWorkflow} onSave={handlePublishSave} onCancel={handlePublishCancel} />
                </Box>
            </Box>
        ) : null
    );

}


function EditWorkflow(props) {

    const [updateDef, setUpdateDef] = React.useState(0);
    const [updateDefId, setUpdateDefId] = React.useState(-1);

    const [workflowList, setWorkflowList] = React.useState([]);
    const [modelList, setModelList] = React.useState([]);
    const [subroutineList, setSubroutineList] = React.useState([]);

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [stepTemplateSelectedIndex, setStepTemplateSelectedIndex] = React.useState(-1); // right panel selected index
    const [contextMenu, setContextMenu] = React.useState(null);
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(false);
    const [saveWorkflowEnabled, setSaveWorkflowEnabled] = React.useState(false);
    const [checkinEnabled, setCheckinEnabled] = React.useState(false);
    const [addWorkflowEnabled, setAddWorkflowEnabled] = React.useState(true);
    const [editWorkflowEnabled, setEditWorkflowEnabled] = React.useState(false);
    const [deleteWorkflowEnabled, setDeleteWorkflowEnabled] = React.useState(false);
    const [publishEnabled, setPublishEnabled] = React.useState(false);

    const [workflowDialogOpen, setWorkflowDialogOpen] = React.useState(false);
    const [workflowDialogTemplate, setWorkflowDialogTemplate] = React.useState([]);
    const [workflowDialogName, setWorkflowDialogName] = React.useState('');
    const [workflowDialogDescription, setWorkflowDialogDescription] = React.useState('');
    const [workflowDialogDocument, setWorkflowDialogDocument] = React.useState('');
    const [workflowDialogType, setWorkflowDialogType] = React.useState('custom');
    const [workflowDialogTitle, setWorkflowDialogTitle] = React.useState('Create Workflow');

    const [workflowEditDialogOpen, setWorkflowEditDialogOpen] = React.useState(false);
    const [workflowEditDialogTitle, setWorkflowEditDialogTitle] = React.useState('Edit Workflow Properties');

    const [checkinDialogOpen, setCheckinDialogOpen] = React.useState(false);
    const [checkinDialogTitle, setCheckinDialogTitle] = React.useState('Checkin Workflow');
    const [checkinDialogWorkflowName, setCheckinDialogWorkflowName] = React.useState('');

    const [revokeDialogOpen, setRevokeDialogOpen] = React.useState(false);
    const [revokeDialogTitle, setRevokeDialogTitle] = React.useState('Revoke Checkout');

    const [workflowDeleteOpen, setWorkflowDeleteOpen] = React.useState(false);
    const [workflowDeleteName, setWorkflowDeleteName] = React.useState('');
    const [workflowSaveIndex, setWorkflowSaveIndex] = React.useState(-1);

    const [workflowSaveOpen, setWorkflowSaveOpen] = React.useState(false);
    const [workflowSaveName, setWorkflowSaveName] = React.useState('');

    const [docList, setDocList] = React.useState([]);
    const [modified, setModified] = React.useState([]);

    const [insertBeforeEnabled, setInsertBeforeEnabled] = React.useState(false);
    const [insertAfterEnabled, setInsertAfterEnabled] = React.useState(false);
    const [stepTabValue, setStepTabValue] = React.useState(0);


    const [editStepList, setEditStepList] = React.useState([]);

    const [stepList, setStepList] = React.useState([]); // Steps allowed to be inserted in current position (from SUBWORKFLOWs and FORMs)
    const [fullStepList, setFullStepList] = React.useState([]); // Steps allowed to be assembled here (SUBWORKFLOWs and FORMs)
    const [stepEditSelectedIndex, setStepEditSelectedIndex] = React.useState(-1);

    /*
    const [stepEditDialogOpen, setStepEditDialogOpen] = React.useState(false);
    const [stepEditDialogTemplate, setStepEditDialogTemplate] = React.useState('');
    const [stepEditDialogName, setStepEditDialogName] = React.useState('');
    const [stepEditDialogDescription, setStepEditDialogDescription] = React.useState('');
    const [stepEditDialogTarget, setStepEditDialogTarget] = React.useState('');
    const [stepEditDialogTitle, setStepEditDialogTitle] = React.useState('');
    */

    const [stepInsertDialogOpen, setStepInsertDialogOpen] = React.useState(false);
    const [stepInsertDialogTemplate, setStepInsertDialogTemplate] = React.useState('');
    const [stepInsertDialogName, setStepInsertDialogName] = React.useState('');
    const [stepInsertDialogDescription, setStepInsertDialogDescription] = React.useState('');
    const [stepInsertDialogTarget, setStepInsertDialogTarget] = React.useState('');
    const [stepInsertDialogTitle, setStepInsertDialogTitle] = React.useState('');
    const [stepTemplateActive, setStepTemplateActive] = React.useState(false);

    const [deleteStepEnabled, setDeleteStepEnabled] = React.useState(false);
    const [stepDeleteOpen, setStepDeleteOpen] = React.useState(false);
    const [stepDeleteName, setStepDeleteName] = React.useState('');
    const [stepDeleteWorkflowName, setStepDeleteWorkflowName] = React.useState('');


    const theme = useTheme();

    const INSERT_BEFORE_TITLE = "Insert Step Before";
    const INSERT_AFTER_TITLE = "Insert Step After";

    React.useEffect(() => {
        loadInstances();
    }, []);

    React.useEffect(() => {
        if (updateDefId >= 0) {
            netGet('/api/workflow/def/' + updateDefId)
                .then(response => response.json())
                .then(def => {
                    if (def && def.id) {
                        if (def.category.startsWith('MODEL')) {
                            let mlist = modelList.map((r) => {
                                if (r.id === def.id) {
                                    return def;
                                }
                                return r;
                            });
                            setModelList(mlist);
                            loadWorkflowDialogTemplateList(workflowList, mlist);
                        } else {
                            // console.log('Updating Workflow Definition: ' + def.id);
                            const ocdef = workflowList[selectedIndex];
                            let slist = workflowList.map((r) => {
                                if (r.id === def.id) {
                                    return def;
                                }
                                return r;
                            }).sort( (a,b) => a.label.localeCompare(b.label));
                            setWorkflowList(slist);
                            const cdef = slist.find((item) => item.id === ocdef?.id);
                            if (cdef) {
                                const i = slist.indexOf(cdef);
                                // console.log('Found def to update ' + cdef + ' at index ' + i);
                                // if (i === selectedIndex) {
                                    updateSelectedItem(cdef, i);
                                //}
                            }
                            loadWorkflowDialogTemplateList(slist, modelList);
                        }
                    }
                }).catch(error => {
                    console.log(`Error fetching def for ${updateDefId}: ${error}`);
                });
        }
    }, [updateDef, updateDefId]);

    React.useEffect(() => {
        netGet('/api/workflow/step/def/list?mask=0')
            .then(response => response.json())
            .then(steps => {
                if (Array.isArray(steps)) {
                    setFullStepList(steps);
                    setStepEditSelectedIndex(-1);
                    setStepList([]);
                    // console.log('STEPS: ' + JSON.stringify(steps));
                }
            }).catch(error => {
                console.log('Error fetching model list: ' + error);
            });

    }, []);

    const getInsertableSteps = (slist) => {
        return slist.filter(item => item.name === 'CALL' || item.name === 'FORM');
    };


    const workflowMessage = (message) => {
        let wevent = message.data;
        // console.log('Received workflow message: ' + wevent.action + ' : ' + wevent.type + ' : ' + wevent.id);
        if (wevent.type === 'WorkflowDefinition') {
            if (wevent.action === 'DELETE') {
                let dindex = -1;
                const def = workflowList.find((item, index) => {
                    if (item.id === wevent.id) {
                        dindex = index;
                        return true;
                    }
                });
                if (def && dindex >= 0) {
                    let wlist = [...workflowList];
                    wlist.splice(dindex, 1);
                    setWorkflowList(wlist);
                    loadWorkflowDialogTemplateList(wlist, modelList);
                } else {
                    let mindex = -1;
                    const model = modelList.find((item, index) => {
                        if (item.id === wevent.id) {
                            mindex = index;
                            return true;
                        }
                    });
                    if (model && mindex >= 0) {
                        let mlist = [...modelList];
                        mlist.splice(mindex, 1);
                        setModelList(mlist);
                        loadWorkflowDialogTemplateList(workflowList, mlist);
                    }
                }
            } else if (wevent.action === 'NEW') {
                loadInstances(wevent.id);
                // setUpdateWorkflowList(updateWorkflowList => updateWorkflowList + 1);
            } else if (wevent.action === 'CHECKOUT' || wevent.action === 'REVOKE_CHECKOUT') {
                let cdef = workflowList.find((item) => item.id === wevent.previousId);
                if (cdef) {
                    cdef.id = wevent.id;
                    setUpdateDef(updateDef => updateDef + 1);
                    setUpdateDefId(wevent.id);
                }
            } else {
                const def = workflowList.find((item) => item.id === wevent.id);
                if (def) {
                    setUpdateDef(updateDef => updateDef + 1);
                    setUpdateDefId(wevent.id);
                }
            }
        }
    };

    MessageHooks["workflow"]["WorkflowAttributesEdit"] = workflowMessage;


    const loadInstances = () => {
        /*
           Do not pull subroutines as they are not useful for top level workflows.
        */
        netGet('/api/workflow/def/list?categ=DEF')
            .then(response => response.json())
            .then(defs => {
                if (defs && Array.isArray(defs)) {
                    // console.log('Workflow Definitions: ' + defs.length);
                    const sdefs = defs.sort( (a,b) => a.label.localeCompare(b.label));
                    setWorkflowList(sdefs);
                }

                netGet('/api/workflow/def/list?categ=MODELS')
                    .then(response2 => response2.json())
                    .then(models => {
                        if (models && Array.isArray(models)) {
                            let mlist = [];
                            let slist = [];
                            models.forEach(item => {
                                if (item.category.endsWith('SUB')) {
                                    slist.push(item);
                                } else {
                                    mlist.push(item);
                                }
                            });
                            // console.log('Workflow Models: ' + mlist.length);
                            // console.log('Subroutines: ' + slist.length);
                            setModelList(mlist);
                            setSubroutineList(slist);

                            loadWorkflowDialogTemplateList(defs, mlist);
                        }

                    }).catch(error => {
                        console.log('Error fetching models: ' + error);
                    });
            }).catch(error => {
                console.log('Error fetching profiles: ' + error);
            });
    };

    const loadWorkflowDialogTemplateList = (defs, models) => {
        let temps = models.concat(defs);
        // temps.sort();
        let tnames = temps.map((def) => def.label).sort( (a,b) => a.localeCompare(b) );
        setWorkflowDialogTemplate(tnames);
    };

    /*
    const loadModels = () => {
        netGet('/api/workflow/def/list?categ=MODEL')
            .then(response => response.json())
            .then(data => {
                if ( data && Array.isArray(data) ) {
                    console.log('Workflow Models: ' + data.length);
                    setModelList(data);

                    let temps = data.concat(workflowList);
                    temps.sort();
                    let tnames = temps.map( (def) => def.label);
                    setWorkflowDialogTemplate(tnames);
                }
            });
    }

    */


    const serverDelete = (def) => {
        netFetch('/api/workflow/def/' + def.id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        })
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not delete workflow definition: status = ' + resp.status);
                }
            });
    };

    const serverCheckout = (index) => {
        const def = workflowList[index];
        netPost('/api/workflow/def/checkout', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkout workflow definition: status = ' + resp.status);
                }
            });
    };

    const serverCheckin = (index, msg) => {
        const def = workflowList[index];
        const message = msg ? msg : '';
        const payload = { definition: def, message: message };
        netPost('/api/workflow/def/checkin', payload)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not checkin workflow definition: status = ' + resp.status);
                }
            });
    };

    const serverRevokeCheckout = (index) => {
        const def = workflowList[index];
        netPost('/api/workflow/def/revoke_checkout', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not revoke checkout workflow definition: status = ' + resp.status);
                }
            });
    };

    const handleContextMenu = (event, index) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
        if (index >= 0) {
            handleItemClick(index);
        }
    };

    const handleContextMenuClose = () => {
        setContextMenu(null);
    };

    const handleItemClick = (index) => {
        /*
        if ( saveRequired(selectedIndex) ) {
            const def = workflowList[selectedIndex];
            if (def) {
                setWorkflowSaveName(def.label);
                setWorkflowSaveIndex(selectedIndex);
                setWorkflowSaveOpen(true);
            }
        }
        */
        setSelectedIndex(index);
        if (isLocked(index)) {
            setCheckoutEnabled(false);
            setDeleteWorkflowEnabled(false);
            const mylock = isLockedByMe(index);
            setCheckinEnabled(mylock);
            setEditWorkflowEnabled(mylock);
        } else {
            setCheckoutEnabled(true);
            setDeleteWorkflowEnabled(true);
            setCheckinEnabled(false);
            setEditWorkflowEnabled(false);
        }
        setPublishEnabled(canPublish(index));
    };

    const updateSelectedItem = (def, index) => {
        const locked = typeof def.user === 'string' && def.user.length > 0;
        const lockedByMe = typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
        const canPublish = (def.flag & 2) == 0;
        setSelectedIndex(index);
        if (locked) {
            setCheckoutEnabled(false);
            setDeleteWorkflowEnabled(false);
            setCheckinEnabled(lockedByMe /* && !saveRequired(index) */);
            setEditWorkflowEnabled(lockedByMe);
        } else {
            setCheckoutEnabled(true);
            setDeleteWorkflowEnabled(true);
            setCheckinEnabled(false);
            setEditWorkflowEnabled(false);
        }
        setPublishEnabled(canPublish);
    };

    const lockIconColor = (index) => {
        const def = workflowList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if (def.user && def.user !== '') {
            if (def.user === UserInfo.info.name) {
                return 'green';
            } else {
                return '#cc0000';
            }
        }
        return 'transparent';
    };

    const publishedIconColor = (index) => {
        const def = workflowList[index];
        // console.log('index: ' + index + ', user=' + def.user);
        if ((def.flag & 2) != 0) {
            return 'black';
        }
        return 'transparent';
    };

    const canPublish = (index) => {
        const def = workflowList[index];
        return ((def.flag & 2) == 0);
    };

    const lockIconLabel = (index) => {
        const def = workflowList[index];
        if (def.user && def.user !== '') {
            return 'Locked by ' + def.user;
        }
        return '';
    };



    const isLockedByMe = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0 && def.user === UserInfo.info.name;
    };

    const isLocked = (index) => {
        let def = workflowList[index];
        return typeof def.user === 'string' && def.user.length > 0;
    };

    const handleCheckoutAction = () => {
        if (selectedIndex >= 0) {
            serverCheckout(selectedIndex);
        }
    };

    const handleMenuCheckout = () => {
        handleContextMenuClose();
        handleCheckoutAction();
    };

    const handleMenuRevokeCheckout = () => {
        handleContextMenuClose();
        handleRevokeCheckoutAction();
    };

    const handleRevokeCheckoutAction = () => {
        setRevokeDialogOpen(true);

    };

    const handleRevokeCheckoutSave = (index) => {
        setRevokeDialogOpen(false);
        if (selectedIndex >= 0) {
            serverRevokeCheckout(selectedIndex);
        }
    };

    const handleRevokeCheckoutCancel = () => {
        handleContextMenuClose();
        setRevokeDialogOpen(false);
    };

    const handleCheckinAction = () => {
        setCheckinDialogOpen(true);
    };

    const handleCheckinSave = (message) => {
        setCheckinDialogOpen(false);
        if (selectedIndex >= 0) {
            serverCheckin(selectedIndex, message);
        }
    };

    const handleCheckinCancel = () => {
        setCheckinDialogOpen(false);
    };

    const handleMenuCheckin = () => {
        handleContextMenuClose();
        handleCheckinAction();
    };

    const handleMenuDeleteWorkflow = () => {
        handleContextMenuClose();
        handleDeleteWorkflowAction();
    };

    const handleDeleteWorkflowAction = () => {
        const workflow = workflowList[selectedIndex];
        setWorkflowDeleteName(workflow.label);
        setWorkflowDeleteOpen(true);
    }

    const deleteWorkflowSave = () => {
        setWorkflowDeleteOpen(false);
        const workflow = workflowList[selectedIndex];
        serverDelete(workflow);
    };

    const deleteWorkflowCancel = () => {

        setWorkflowDeleteOpen(false);

    };

    const handleCreateWorkflowAction = () => {
        handleContextMenuClose();
        setWorkflowDialogName('');
        setWorkflowDialogDescription('');
        setWorkflowDialogDocument('');
        setWorkflowDialogType('custom');
        if (Array.isArray(docList) && docList.length > 0) {
            setWorkflowDialogOpen(true);
        } else {
            netGet('/api/doc/roots?sort=alpha')
                .then(response => response.json())
                .then(docs => {
                    if (Array.isArray(docs)) {
                        // console.log('Got ' + docs.length + ' documents.');
                        setDocList(docs);
                        setWorkflowDialogOpen(true);
                    }
                }).catch(error => {
                    console.log('Error fetching document list: ' + error);
                });
        }
    };

    const handleEditWorkflowAction = () => {
        handleContextMenuClose();
        const workflow = workflowList[selectedIndex];
        setWorkflowDialogName(workflow.label);
        setWorkflowDialogDescription(workflow.description);
        setWorkflowDialogDocument(workflow.attributes['document']);
        setWorkflowDialogType(workflow.attributes['type']);
        if (Array.isArray(docList) && docList.length > 0) {
            setWorkflowEditDialogOpen(true);
        } else {
            netGet('/api/doc/roots?sort=alpha')
                .then(response => response.json())
                .then(docs => {
                    if (Array.isArray(docs)) {
                        // console.log('Got ' + docs.length + ' documents.');
                        setDocList(docs);
                        setWorkflowEditDialogOpen(true);
                    }
                }).catch(error => {
                    console.log('Error fetching document list: ' + error);
                });
        }
    };

    const workflowDialogCreateHandler = (props) => {
        handleContextMenuClose();
        setWorkflowDialogOpen(false);
        if (typeof props.name === 'string' && props.name.length > 0) {
            // generate a unique name based on the label 
            // it would be safer to use an UUID but this way the name is more user friendly when examining the DB directly
            const timestamp = Math.floor(new Date().getTime() / 100);
            const suffix = timestamp.toString(16);
            const id = `${props.name.replace(/\s+/gm, '_')}_${suffix}`.toUpperCase();
            // console.log('name: ' + props.name + ',id: ' + id);
            let newWorkflow = {
                name: id,
                label: props.name,
                description: props.description,
                flag: 0,
                category: 'DEF',
                steps: [],
                attributes: { type: props.type }
            }
            if (props.type === 'doc') {
                newWorkflow.attributes['document'] = props.document;
                newWorkflow.attributes['documentTitle'] = props.documentTitle;
            }
            if (typeof props.template === 'string' && props.template.length > 0 && props.template !== 'None') {
                const templates = workflowList.concat(modelList);
                let def = templates.find((item) => item.label === props.template);
                if (def) {
                    def.steps.forEach((item) => {
                        let step = { ...item };
                        if (step.name === 'CALL') {
                            const target = step.properties.target;
                            const sdef = subroutineList.find(item => item.name === target);
                            if (sdef) {
                                const substeps = [...sdef.steps];
                                step.properties.substeps = substeps;
                            }
                        }
                        newWorkflow.steps.push(step);
                    });
                    if (def.category.endsWith('SUB')) {
                        newWorkflow.category = 'DEF_SUB';
                    }
                } else {
                    newWorkflow.steps.push(
                        {
                            name: 'START',
                            id: 0,
                            label: 'START',
                        }
                    );
                }

            } else {
                newWorkflow.steps.push(
                    {
                        name: 'START',
                        id: 0,
                        label: 'START',
                    }
                );
            }
            // console.log(JSON.stringify(newWorkflow));
            addServerWorkflow(newWorkflow);
        }
    };

    const workflowDialogEditHandler = (props) => {
        handleContextMenuClose();
        setWorkflowEditDialogOpen(false);
        if (typeof props.name === 'string' && props.name.length > 0) {
            // generate a unique name based on the label 
            // it would be safer to use an UUID but this way the name is more user friendly when examining the DB directly
            const timestamp = Math.floor(new Date().getTime() / 100);
            const suffix = timestamp.toString(16);
            const id = `${props.name.replace(/\s+/gm, '_')}_${suffix}`.toUpperCase();
            // console.log('name: ' + props.name + ',id: ' + id);
            const workflow = workflowList[selectedIndex];
            if (workflow) {
                workflow.label = props.name;
                workflow.description = props.description;
                workflow.attributes['type'] = props.type;

                if (props.type === 'doc') {
                    workflow.attributes['document'] = props.document;
                    workflow.attributes['documentTitle'] = props.documentTitle;
                }
                saveToServer(workflow);
                // console.log(JSON.stringify(newWorkflow));
            }
        }
    };

    const addServerWorkflow = (workflow) => {
        netPost('/api/workflow/def', workflow)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not create workflow definition: status = ' + resp.status);
                }
            });
    };

    const workflowDialogCancelHandler = () => {
        setWorkflowDialogOpen(false);
    }

    const workflowEditDialogCancelHandler = () => {
        setWorkflowEditDialogOpen(false);
    }


    const handleChange = (event) => {
        // setInstanceIndex(event.target.value);
    };

    const saveToServer = (def) => {
        // console.log('Saving ' + JSON.stringify(def));
        netPost('/api/workflow/def/update', def)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not save workflow definition: status = ' + resp.status);
                } else {
                    delete def.saveSteps;
                    setSaveWorkflowEnabled(false);
                    handleItemClick(selectedIndex);
                    unmarkModified();
                }
            });
    };

    const saveWorkflowHandler = () => {
        saveToServer(workflowList[selectedIndex]);
    };

    const workflowChangeHandler = (modified, index) => {
        if (modified) {
            markModified();
            setSaveWorkflowEnabled(true);
            setCheckoutEnabled(false);
            setDeleteWorkflowEnabled(false);
            setCheckinEnabled(false);
            setPublishEnabled(false);
            setEditWorkflowEnabled(isLockedByMe(index));
        } else {
            setSaveWorkflowEnabled(false);
            handleItemClick(selectedIndex);
            unmarkModified();
        }
    };

    const markModified = () => {
        const m = [...modified];
        if (!m.includes(selectedIndex)) {
            m.push(selectedIndex);
        }
        setModified(m);

        setSaveWorkflowEnabled(true);
        // setUndoSaveEnabled(true);
        // modified inhibites checkin
        setCheckinEnabled(false);
    };

    const unmarkModified = () => {
        const m = modified.filter(item => item !== selectedIndex);
        setModified(m);
        const mylock = isLockedByMe(selectedIndex);
        setCheckinEnabled(mylock);
        setEditWorkflowEnabled(mylock);
        setSaveWorkflowEnabled(false);
        // setUndoSaveEnabled(false);
    };

    const doUnmarkModified = (index) => {
        const m = modified.filter(item => item !== index);
        setModified(m);
        // setCheckinEnabled(isLockedByMe(index));
    };


    const modifiedColor = (index) => {
        return saveRequired(index) ? 'black' : 'transparent';
    };

    const modifiedLabel = (index) => {
        return saveRequired(index) ? 'Modified' : null;
    }

    const saveRequired = (index) => {
        return modified.includes(index);
    };

    const saveWorkflowCancel = () => {
        setWorkflowSaveOpen(false);
        // just keep the current state it may work out or may loose changes later
    };

    const saveWorkflowSave = (index) => {
        setWorkflowSaveOpen(false);
        doUnmarkModified(index);
        saveToServer(workflowList[index]);
    };

    const saveWorkflowDiscard = (index) => {
        setWorkflowSaveOpen(false);
        const def = workflowList[index];
        if (def && typeof def.saveSteps !== 'undefined') {
            // put back the steps
            def.steps = [...def.saveSteps];
            delete def.saveSteps;
            // report we have no changes
            workflowChangeHandler(false, index);
        }
    };

    const stepTemplateItemClicked = (index) => {
        if (index >= 0 && index < stepList.length) {
            const stepTemp = stepList[index];
            setStepTemplateSelectedIndex(index);
            setStepInsertDialogTemplate(stepTemp.name);
            setStepInsertDialogOpen(true);
        }
    };

    const handleStepTabChange = (event, newValue) => {
        setStepTabValue(newValue);
        computeStepTabList(newValue, stepEditSelectedIndex);
        transitionTemplateActive();
    };

    const computeStepTabList = (tab, stepIndex) => {
        setStepTemplateSelectedIndex(-1);
        switch (tab) {
            case 0:
                // insert before
                if (selectedIndex >= 0 && stepIndex >= 0) {
                    const steps = workflowList[selectedIndex].steps;
                    const bsteps = computeInsertBefore(steps, stepIndex);
                    setStepList(bsteps);
                    setStepInsertDialogTitle(INSERT_BEFORE_TITLE);
                }
                break;
            case 1:
                // insert after
                if (selectedIndex >= 0 && stepIndex >= 0) {
                    const steps = workflowList[selectedIndex].steps;
                    const asteps = computeInsertAfter(steps, stepIndex);
                    setStepList(asteps);
                    setStepInsertDialogTitle(INSERT_AFTER_TITLE);
                }
                break;
            default:
                setStepList([]);
                break;
        }
    };

    /*
    const computeEditStepList = (stepIndex) => {
        let editList = [];
        let editIndex = -1;
        let defStep;
        if ( selectedIndex >= 0 && stepIndex >= 0 ) {
            const steps = workflowList[selectedIndex].steps;
            const cstep = steps[stepIndex];
            if (cstep) {
                setStepEditDialogName(cstep.label);
                setStepEditDialogDescription(cstep.description);
                setStepEditDialogTarget(cstep.properties && cstep.properties.target ? cstep.properties.target : '');
                console.log('step def name = ' + cstep.name);
                defStep = getStepDefinition(cstep.name);
                if (defStep) {
                    const si = fullStepList.indexOf(defStep);
                    // editList.push(si); // the current step is on the list,  of course
                    // find what steps are valid after previous step
                    if (si > 0) {
                        const index = si - 1;
                        const nextStep = index + 1 >= steps.length - 1 ? undefined : getStepDefinition(steps[index + 2].name);
                        fullStepList.forEach((item) => {
                            if (checkAfter(defStep, item)) {
                                if (nextStep) {
                                    if (checkBefore(item, nextStep)) {
                                        editList.push(item);
                                    }
                                } else {
                                    editList.push(item);
                                }
                            }
                        });
                    }
                    if (editList.length <= 0) {
                        editList.push(defStep); // at least the current step
                    }
                } else {
                    console.log('No definition for step: ' + cstep.name);
                }
                editIndex = editList.indexOf(defStep);

            }
        }
        setEditStepList(editList);
        setStepEditSelectedIndex(editIndex);
        setStepEditDialogTemplate(defStep ? defStep.name : undefined);
    };
    */

    // get the step definition from the master table as the one 
    // in the workflows don't contain all the rules 
    const getStepDefinition = (name) => {
        return fullStepList.find((item) => item.name === name);
    };

    // check if step can preceed next 
    // steps should be master step definitions with schema rules
    const checkAfter = (anchor, next) => {
        return (anchor.properties.allowAfter.includes('ANY') || anchor.properties.allowAfter.includes(next.name)) &&
            (next.properties.allowBefore.includes('ANY') || next.properties.allowBefore.includes(anchor.name));
    };

    const checkBefore = (previous, anchor) => {
        return (anchor.properties.allowBefore.includes('ANY') || anchor.properties.allowBefore.includes(previous.name)) &&
            (previous.properties.allowAfter.includes('ANY') || previous.properties.allowAfter.includes(anchor.name));
    };

    const computeInsertBefore = (steps, index) => {
        if (index <= 0) {
            // setStepList([]); // cannot insert before first
            return [];
        } else {
            const validSteps = getInsertableSteps(fullStepList);
            const step = getStepDefinition(steps[index].name);
            const previousStep = getStepDefinition(steps[index - 1].name);
            // console.log('checking step ' + step.name + ' : ' +  step.label + ' : ' + index + ' : ' + step.properties);
            let slist = validSteps.filter((item) => {
                if (checkAfter(item, step) && checkBefore(previousStep, item)) {
                    return true;
                }
                return false;
            });
            // setStepList(slist);
            return slist;
        }

    };

    const computeInsertAfter = (steps, index) => {
        const step = getStepDefinition(steps[index].name);
        const validSteps = getInsertableSteps(fullStepList);
        const nextStep = index >= steps.length - 1 ? undefined : getStepDefinition(steps[index + 1].name);
        let slist = validSteps.filter((item) => {
            if (checkAfter(step, item)) {
                if (nextStep) {
                    if (checkBefore(item, nextStep)) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
            return false;
        });
        // setStepList(slist);
        return slist;
    };

    const stepSelectionChange = (steps, index, subindex) => {
        if (index < 0) {
            setInsertAfterEnabled(false);
            setInsertBeforeEnabled(false);
            setDeleteStepEnabled(false);
            setStepList([]);
            setStepEditSelectedIndex(-1);
        } else {
            const validBefore = computeInsertBefore(steps, index);
            const validAfter = computeInsertAfter(steps, index);
            setInsertBeforeEnabled(validBefore && validBefore.length > 0);
            setInsertAfterEnabled(validAfter && validAfter.length > 0);
            computeStepTabList(stepTabValue, index);
            setStepEditSelectedIndex(index);
            if (index === 0) {
                setStepTabValue(1);
                computeStepTabList(1, 0);
            } else if (index >= steps.length - 1) {
                setStepTabValue(0);
                computeStepTabList(0, index);
            }
            const cstep = steps[index];
            if (cstep) {
                const cdef = getStepDefinition(cstep.name);
                console.log('Selected step: ' + JSON.stringify(cstep));
                // if (cdef && (cdef.name === 'CALL' || cdef.name === 'FORM')) {
                if (cdef) {
                    setDeleteStepEnabled(true);
                } else {
                    setDeleteStepEnabled(false);
                }
            } else {
                setDeleteStepEnabled(false);
            }
        }


    };

    const handleStepInsert = (tabIndex) => {
        setStepTabValue(tabIndex);
        transitionTemplateActive();
    };

    const handleStepDelete = () => {

    };

    const transitionTemplateActive = () => {
        if (stepTemplateActive) {
            setStepTemplateActive(false);
            setTimeout(() => setStepTemplateActive(true), 500);
        } else {
            setStepTemplateActive(true);
        }
    };

    const stepInsertDialogSaveHandler = (nameValue, descrValue, stepName, subOrFormValue) => {
        setStepInsertDialogOpen(false);
        // console.log(`Result: ${nameValue}, ${descrValue}, ${stepName}, ${subOrFormValue}`);
        const workflow = workflowList[selectedIndex];
        let steps = workflow.steps;
        if (typeof workflow.saveSteps === 'undefined') {
            workflow.saveSteps = [...steps]; // this should save server steps safe
        }
        const stepTemplate = stepList[stepEditSelectedIndex];
        let position = stepEditSelectedIndex;
        if (stepTabValue === 1) {
            position = position + 1;
        }
        let nstep = {
            name: stepName,
            label: nameValue,
            description: descrValue,
            flag: 4,
            id: position,
        }
        if (stepName === 'CALL' || stepName === 'FORM') {
            nstep['properties'] = {
                target: subOrFormValue,
            };
        }
        if (stepName === 'CALL') {
            // subroutineList
            const sdef = subroutineList.find(item => item.name === subOrFormValue);
            if (sdef) {
                const substeps = [...sdef.steps];
                nstep.properties.substeps = substeps;
            }
        }
        if (position >= steps.length) {
            steps.push(nstep);
        } else {
            steps.splice(position, 0, nstep);
            for (let i = position + 1; i < steps.length; i++) {
                steps[i].id = i;
            }
        }
        // serverSave(workflow);
        markModified();
    };

    const stepInsertDialogCancelHandler = () => {
        setStepInsertDialogOpen(false);
    };

    const handleDeleteStep = () => {
        const workflow = workflowList[selectedIndex];
        if (workflow) {
            setStepDeleteWorkflowName(workflow.label);
            const steps = workflow.steps;
            const cstep = steps[stepEditSelectedIndex];
            if (cstep) {
                const cdef = getStepDefinition(cstep.name);
                // if (cdef && (cdef.name === 'CALL' || cdef.name === 'FORM')) {
                if (cdef ) {
                    setStepDeleteOpen(true);
                    setStepDeleteName(cstep.label);
                }
            } else {
                setStepDeleteName('');
            }
        } else {
            setStepDeleteWorkflowName('');
        }
    };

    const deleteStepSave = () => {
        setStepDeleteOpen(false);
        const workflow = workflowList[selectedIndex];
        if (workflow) {
            let steps = workflow.steps;
            if (typeof workflow.saveSteps === 'undefined') {
                workflow.saveSteps = [...steps]; // this should save server steps safe
            }
            const cstep = steps[stepEditSelectedIndex];
            if (cstep) {
                const cdef = getStepDefinition(cstep.name);
                // if (cdef && (cdef.name === 'CALL' || cdef.name === 'FORM')) {
                if (cdef ) {
                    const position = stepEditSelectedIndex;
                    if (position > 0) {
                        // console.log('Deleting step ' + steps[position].label);
                        steps.splice(position, 1);
                        for (let i = position; i < steps.length; i++) {
                            steps[i].id = i;
                        }
                        // serverSave(workflow);
                        markModified();
                    }
                }
            }
        }
    };

    const deleteStepCancel = () => {
        setStepDeleteOpen(false);
    };

    return (

        <Box sx={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '25% 3px 1fr 3px 25%', gridTemplateRows: '100%' }}>

            <Box sx={{ position: 'relative', width: '100%', height: '100%', padding: '1ex', paddingTop: '2ex', display: 'grid', gridTemplateRows: 'min-content min-content minmax(10px,1fr) min-content' }}>
                <Typography align="center" sx={{ paddingTop: '8px', paddingBottom: '2ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Workflows</Typography>
                <TextField
                    id="mwa-search"
                    type="search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Box
                    sx={{ position: 'relative', minHeight: '2em', overflowY: 'auto' }}
                    onContextMenu={(event) => handleContextMenu(event, -1)}
                >

                    <List sx={{ minHeight: '2em' }}>
                        {
                            workflowList.map((def, index) => (
                                <ListItem
                                    key={'mwwa-' + index}
                                    sx={{ padding: 0, paddingTop: 0, paddingBottom: 0, alignItems: 'baseline' }}
                                    onContextMenu={(event) => handleContextMenu(event, index)}
                                >
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: lockIconColor(index), fontSize: '12px' }}>
                                        <Tooltip title={lockIconLabel(index)}>
                                            <span>
                                                <LockIcon fontSize="inherit" />
                                            </span>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: modifiedColor(index), fontSize: '12px', paddingTop: '0.5ex' }}>
                                        <Tooltip title={modifiedLabel(index)}>
                                            <Typography sx={{ paddingLeft: '4px' }}>*</Typography>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ alignItems: 'baseline', float: 'left', color: publishedIconColor(index), fontSize: '12px' }}>
                                        <CheckIcon fontSize="inherit" />
                                    </Box>
                                    <ListItemButton selected={selectedIndex === index} onClick={(event) => handleItemClick(index)} sx={{ paddingLeft: '4px', fontStyle: def.category.endsWith('SUB') ? 'italic' : 'normal' }}>
                                        <ListItemText>{def.label}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            )
                            )
                        }
                    </List>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleContextMenuClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleMenuCheckout} disabled={!checkoutEnabled}>Checkout</MenuItem>
                        <MenuItem onClick={handleMenuRevokeCheckout} disabled={!checkinEnabled}>Revoke Checkout</MenuItem>
                        <MenuItem onClick={saveWorkflowHandler} disabled={!saveWorkflowEnabled}>Save</MenuItem>
                        <MenuItem onClick={handleMenuCheckin} disabled={!checkinEnabled}>Checkin</MenuItem>
                        <MenuItem onClick={handleCreateWorkflowAction} disabled={!addWorkflowEnabled}>Add</MenuItem>
                        <MenuItem onClick={handleEditWorkflowAction} disabled={!editWorkflowEnabled}>Properties</MenuItem>
                        <MenuItem onClick={handleMenuDeleteWorkflow} disabled={!deleteWorkflowEnabled}>Delete</MenuItem>
                    </Menu>
                    <WorkflowCheckinDialog open={checkinDialogOpen} onSave={handleCheckinSave} onCancel={handleCheckinCancel}
                        title={checkinDialogTitle} workflowName={checkinDialogWorkflowName} />
                    <WorkflowRevokeCheckoutDialog open={revokeDialogOpen} onSave={handleRevokeCheckoutSave} title={revokeDialogTitle}
                        onCancel={handleRevokeCheckoutCancel} workflowName={checkinDialogWorkflowName} />
                </Box>
                <Box sx={{ padding: '1ex', whiteSpace: 'nowrap', overflowX: 'hidden' }} color={theme.palette.primary.main}>
                    <Tooltip title="Checkout Workflow">
                        <IconButton color="inherit" disabled={!checkoutEnabled} onClick={handleCheckoutAction} sx={{ paddingLeft: '2px', paddingRight: '6px' }}>
                            <OutputIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Revoke Checkout Workflow">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleRevokeCheckoutAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <ReplayIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save Workflow">
                        <IconButton color="inherit" disabled={!saveWorkflowEnabled} sx={{ paddingLeft: '6px', paddingRight: '6px' }} onClick={saveWorkflowHandler}>
                            <SaveAltIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Checkin Workflow">
                        <IconButton color="inherit" disabled={!checkinEnabled} onClick={handleCheckinAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <ExitToAppIcon color="inherit" sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Create a Workflow">
                        <IconButton color="inherit" sx={{ marginLeft: '1em', paddingLeft: '6px', paddingRight: '6px' }} disabled={!addWorkflowEnabled} onClick={handleCreateWorkflowAction} >
                            <AddIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Workflow Properties">
                        <IconButton color="inherit" sx={{ paddingLeft: '2px', paddingRight: '6px' }} disabled={!editWorkflowEnabled} onClick={handleEditWorkflowAction}>
                            <EditAttributesIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Workflow">
                        <IconButton color="inherit" disabled={!deleteWorkflowEnabled} onClick={handleDeleteWorkflowAction} sx={{ paddingLeft: '6px', paddingRight: '6px' }}>
                            <DeleteIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                    <CreateWorkflowDialog open={workflowDialogOpen} documentList={docList} onSave={workflowDialogCreateHandler} onCancel={workflowDialogCancelHandler}
                        template={workflowDialogTemplate} name={workflowDialogName} description={workflowDialogDescription}
                        title={workflowDialogTitle} />
                    <CreateWorkflowDialog editOnly open={workflowEditDialogOpen} documentList={docList} onSave={workflowDialogEditHandler} onCancel={workflowEditDialogCancelHandler}
                        template={workflowDialogTemplate} name={workflowDialogName} description={workflowDialogDescription} document={workflowDialogDocument} type={workflowDialogType}
                        title={workflowEditDialogTitle} />
                    <WorkflowDeleteDialog title="Delete Workflow" open={workflowDeleteOpen} workflowName={workflowDeleteName}
                        onSave={deleteWorkflowSave} onCancel={deleteWorkflowCancel} />
                    <WorkflowSaveDialog title="Save Workflow" open={workflowSaveOpen} workflowName={workflowSaveName} workflowIndex={workflowSaveIndex}
                        onSave={saveWorkflowSave} onCancel={saveWorkflowCancel} onDiscard={saveWorkflowDiscard} />
                </Box>
            </Box>

            <Divider orientation="vertical" />


            <Box sx={{ overflowY: 'auto', position: 'relative', height: '100%' }}>

                <Box sx={{ height: '100%' }}>
                    {
                        workflowList && workflowList.length > 0 ?
                            <WorkflowDefinitionAttributesEdit def={workflowList[selectedIndex]} onChange={workflowChangeHandler} workflowIndex={selectedIndex}
                                saveEnabled={saveWorkflowEnabled} workflowList={workflowList} onSelection={stepSelectionChange} insertAfterEnabled={insertAfterEnabled}
                                insertBeforeEnabled={insertBeforeEnabled} onInsert={handleStepInsert} deleteStepEnabled={deleteStepEnabled} onDelete={handleDeleteStep} />
                            : null
                    }
                </Box>

            </Box>

            <Divider orientation="vertical" />

            <Box sx={{ overflowY: 'auto', position: 'relative', height: '100%', padding: '1ex', paddingTop: '2ex' }}>
                <Typography align="center" sx={{ display: 'block', paddingTop: '8px', paddingBottom: '1ex', fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>Steps</Typography>
                <Tabs value={stepTabValue} onChange={handleStepTabChange} aria-label="icon label steps tabs" sx={{ padding: '4px' }} centered>
                    {/* <Tab sx={{padding: '4px 6px', minWidth: '70px'}} icon={<Tooltip title="Library" ><CategoryIcon /></Tooltip>}/> */}
                    <Tab sx={{ padding: '4px 6px', minWidth: '70px' }} icon={<Tooltip title="Insert Before" ><span><InsertBeforeIcon /></span></Tooltip>} disabled={!insertBeforeEnabled} />
                    <Tab sx={{ padding: '4px 6px', minWidth: '70px' }} icon={<Tooltip title="Insert After"><span><InsertAfterIcon /></span></Tooltip>} disabled={!insertAfterEnabled} />
                </Tabs>
                <Box>
                    <Zoom in={stepTemplateActive}>
                        <List sx={{ minHeight: '2em' }}>
                            {
                                stepList.map((step, index) => (
                                    <ListItem
                                        key={'mwws-' + index}
                                        sx={{ padding: '4px', paddingTop: 0, paddingBottom: 0 }}

                                    >

                                        <ListItemButton onClick={() => stepTemplateItemClicked(index)} selected={stepTemplateSelectedIndex === index}>
                                            <ListItemText>{step.label}</ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                )
                                )
                            }
                        </List>
                    </Zoom>
                    {/* 
                    <CreateStepWorkflowDialog open={stepDialogOpen} onSave={stepDialogSaveHandler} onCancel={stepDialogCancelHandler} 
                        template={stepDialogTemplate} name={stepDialogName} description={stepDialogDescription} stepList={stepList} 
                        step={Array.isArray(stepList) && stepList[templateSelectedIndex]} editOnly={false}
                        subroutine={''} form={''}
                        title={stepDialogTitle} />
                    */}
                    <CreateStepWorkflowDialog open={stepInsertDialogOpen} onSave={stepInsertDialogSaveHandler} onCancel={stepInsertDialogCancelHandler}
                        template={stepInsertDialogTemplate} name={stepInsertDialogName} description={stepInsertDialogDescription} stepList={stepList}
                        step={Array.isArray(stepList) && stepList[stepTemplateSelectedIndex]}
                        subroutine={''} form={''} editOnly={false}
                        title={stepInsertDialogTitle} />
                    <StepDeleteDialog title="Delete Step" open={stepDeleteOpen} stepName={stepDeleteName} workflowName={stepDeleteWorkflowName}
                        onSave={deleteStepSave} onCancel={deleteStepCancel} />
                </Box>
            </Box>

        </Box>
    );

}


export { WorkflowDefinitionAttributesEdit, EditWorkflow, };