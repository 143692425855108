import { createSlice } from '@reduxjs/toolkit'

/**
 * State of the menu system in order to isolate UI changes comming from backend notifications.
 */
export const menu = createSlice({
    name: 'menu',
    initialState: {
        currentMenu: '',
        pageIndex: 0,
    },
    reducers: {
        setCurrentMenu: (state, action) => {
            state.currentMenu = action.payload;
            console.log('Setting currentMenu = ' + action.payload + ', pageIndex = ' + state.pageIndex);
        },
        setPageIndex: (state, action) => {
            state.pageIndex = action.payload;
            console.log('Setting pageIndex = ' + action.payload + ', currentMenu = ' + state.currentMenu);
        }
    },
});

export const { setCurrentMenu, setPageIndex } = menu.actions;